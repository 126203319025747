<template>
  <div class="popup bg-b-lue">
    <div class="modal">
      <div class="frame-35811">
        <div class="frame-35805">
          <div class="frame-35812">
            <svg
              v-if="windowWidth >= 1156"
              width="48"
              height="48"
              viewBox="0 0 48 48"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                d="M40.0612 15.4388L29.5613 4.93875C29.4218 4.7995 29.2564 4.68908 29.0743 4.6138C28.8922 4.53851 28.697 4.49985 28.5 4.5H10.5C9.70435 4.5 8.94129 4.81607 8.37868 5.37868C7.81607 5.94129 7.5 6.70435 7.5 7.5V40.5C7.5 41.2957 7.81607 42.0587 8.37868 42.6213C8.94129 43.1839 9.70435 43.5 10.5 43.5H37.5C38.2957 43.5 39.0587 43.1839 39.6213 42.6213C40.1839 42.0587 40.5 41.2957 40.5 40.5V16.5C40.5002 16.303 40.4615 16.1078 40.3862 15.9257C40.3109 15.7436 40.2005 15.5782 40.0612 15.4388ZM30 9.62063L35.3794 15H30V9.62063ZM37.5 40.5H10.5V7.5H27V16.5C27 16.8978 27.158 17.2794 27.4393 17.5607C27.7206 17.842 28.1022 18 28.5 18H37.5V40.5Z"
                fill="white"
              />
              <path
                d="M28.1924 21.2336L31.7677 24.8081C31.9052 24.9456 32.0142 25.1088 32.0886 25.2884C32.163 25.4679 32.2013 25.6604 32.2013 25.8548C32.2013 26.0492 32.163 26.2417 32.0886 26.4213C32.0142 26.6009 31.9052 26.7641 31.7677 26.9015L23.1487 35.5205H30.7207C30.9434 35.5205 31.1571 35.609 31.3146 35.7665C31.4722 35.924 31.5607 36.1377 31.5607 36.3605C31.5607 36.5833 31.4722 36.7969 31.3146 36.9545C31.1571 37.112 30.9434 37.2005 30.7207 37.2005H17.2801C16.8875 37.2005 16.5111 37.0446 16.2335 36.767C15.9559 36.4895 15.8 36.113 15.8 35.7205V32.1454V32.1451H16C15.9995 31.9769 16.0324 31.8104 16.0968 31.655C16.1612 31.4997 16.2558 31.3587 16.3752 31.2403L28.1924 21.2336ZM28.1924 21.2336C28.0549 21.0961 27.8918 20.9871 27.7122 20.9127C27.5326 20.8383 27.3401 20.8 27.1457 20.8C26.9513 20.8 26.7588 20.8383 26.5792 20.9127C26.3996 20.9871 26.2365 21.0961 26.099 21.2336C26.099 21.2336 26.099 21.2336 26.099 21.2336L16.2344 31.0983C16.2343 31.0984 16.2342 31.0984 16.2341 31.0985L28.1924 21.2336ZM27.6129 28.6801L20.7726 35.5205H17.4801V32.2279L24.3204 25.3876L27.6129 28.6801ZM30.4378 25.8552L28.8006 27.4924L25.5088 24.2L27.1461 22.5627L30.4378 25.8552Z"
                fill="rgb(17, 54, 90)"
                stroke="white"
                stroke-width="0.4"
              />
            </svg>
            <div class="create-a-manual-order">Create a Manual order</div>
          </div>
          <div
            style="cursor: pointer; display: flex"
            v-on:click.prevent="hideManualOrderModal()"
          >
            <svg
              width="36 "
              height="36"
              viewBox="0 0 36 36"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                d="M28.9206 27.3287C29.0251 27.4332 29.108 27.5573 29.1646 27.6939C29.2212 27.8305 29.2503 27.9768 29.2503 28.1246C29.2503 28.2725 29.2212 28.4188 29.1646 28.5554C29.108 28.692 29.0251 28.8161 28.9206 28.9206C28.8161 29.0251 28.692 29.108 28.5554 29.1646C28.4188 29.2212 28.2725 29.2503 28.1246 29.2503C27.9768 29.2503 27.8305 29.2212 27.6939 29.1646C27.5573 29.108 27.4332 29.0251 27.3287 28.9206L17.9996 19.5901L8.67059 28.9206C8.45949 29.1317 8.17318 29.2503 7.87465 29.2503C7.57611 29.2503 7.28981 29.1317 7.07871 28.9206C6.86762 28.7095 6.74902 28.4232 6.74902 28.1246C6.74902 27.8261 6.86762 27.5398 7.07871 27.3287L16.4092 17.9996L7.07871 8.67059C6.86762 8.45949 6.74902 8.17318 6.74902 7.87465C6.74902 7.57611 6.86762 7.28981 7.07871 7.07871C7.28981 6.86762 7.57611 6.74902 7.87465 6.74902C8.17318 6.74902 8.45949 6.86762 8.67059 7.07871L17.9996 16.4092L27.3287 7.07871C27.5398 6.86762 27.8261 6.74902 28.1246 6.74902C28.4232 6.74902 28.7095 6.86762 28.9206 7.07871C29.1317 7.28981 29.2503 7.57611 29.2503 7.87465C29.2503 8.17318 29.1317 8.45949 28.9206 8.67059L19.5901 17.9996L28.9206 27.3287Z"
                fill="white"
              />
            </svg>
          </div>
        </div>
      </div>
      <div class="frame-35654">
        <div class="frame-35656">
          <div class="frame-35815">
            <div class="input-sections1">
              <div class="input-field-title">
                Company Name <span style="color: red">*</span>
              </div>
              <div class="frame-35739">
                <input
                  type="text"
                  :value="
                    selectedCompanyName !== '' ? selectedCompanyName : null
                  "
                  v-on:input="selectedCompanyName = $event.target.value.trim()"
                  placeholder="Enter Company Name*"
                  required
                  class="input-field"
                />
              </div>
            </div>
            <div class="input-sections1">
              <div class="input-field-title">
                Country <span style="color: red">*</span>
              </div>
              <div class="frame-35740">
                <div
                  class="input-group"
                  style="
                    justify-content: center;
                    position: relative;
                    width: 100%;
                  "
                >
                  <input
                    type="text"
                    placeholder="Country/Region*"
                    :value="selectedCountry.name"
                    class="input-field"
                    ref="searchInput"
                    @input="updateSelectedCountry"
                    @click="showResults"
                  />
                  <div
                    class="country-results"
                    v-if="filteredCountries.length && showResultsFlag"
                    ref="countryResults"
                  >
                    <div
                      v-for="country in filteredCountries"
                      :key="country.id"
                      @click="selectCountry(country)"
                    >
                      <template v-if="isWindows()"
                        ><img
                          :src="country.flagImage"
                          alt="Flag"
                          class="flag-image"
                        />
                        {{ country.name }}</template
                      >
                      <template v-else
                        >{{ country.flagEmoji }} {{ country.name }}</template
                      >
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div class="input-sections1">
              <div class="input-field-title">Upload Proforma Invoice</div>
              <div class="frame-357442">
                <input
                  style="padding: 10px 0"
                  id="file-upload"
                  type="file"
                  accept="image/*,.pdf,.doc,.docx,.rtf,.odt"
                  @change="handleFileUpload"
                />
              </div>
            </div>
          </div>

          <div class="frame-35815">
            <div class="input-sections1">
              <div class="input-field-title">
                Company Address <span style="color: red">*</span>
              </div>
              <textarea
                class="additional-address"
                placeholder="Enter Address (Minimum 4 words)"
                v-model.trim="additionalAddress"
                required
              ></textarea>
            </div>
            <div class="input-sections1">
              <div class="input-field-title">More Information</div>
              <textarea
                class="additional-address"
                placeholder="Provide More Information About The Company (optional)"
                v-model.trim="additionalInformation"
              ></textarea>
            </div>
            <div class="input-sections1">
              <div class="input-field-title">Phone</div>
              <div
                class="input-group"
                style="
                  display: flex;
                  flex-direction: row;
                  justify-content: space-between;
                  background-color: white;
                  border: 1px solid rgba(87, 87, 87, 0.5);
                  border-radius: 14px;
                  overflow: hidden;
                  width: 100%;
                "
              >
                <select
                  v-model="selectedCountryCode"
                  class="country-dropdown"
                  style="padding: 10px"
                >
                  <option value="" disabled>Select a country</option>
                  <option
                    v-for="country in countries"
                    :value="country"
                    :key="country.code"
                    class="country-option"
                  >
                    {{ country.name }} - (+{{
                      getFirstDialCode(country.dialCode)
                    }})
                  </option>
                </select>
                <input
                  type="tel"
                  v-model.trim="phone"
                  placeholder="Phone"
                  style="
                    width: 65%;
                    border: none;
                    outline: none;
                    padding: 10px 24px;
                  "
                  inputmode="tel"
                  autocomplete="tel"
                  required
                  pattern="[0-9()+\()-]*"
                />
              </div>
            </div>
          </div>
          <div class="frame-35815" style="align-items: center">
            <div class="input-sections1">
              <div class="input-field-title">Landline</div>
              <div
                class="input-group"
                style="
                  display: flex;
                  flex-direction: row;
                  justify-content: space-between;
                  background-color: white;
                  border: 1px solid rgba(87, 87, 87, 0.5);
                  border-radius: 14px;
                  overflow: hidden;
                  width: 100%;
                "
              >
                <input
                  type="tel"
                  v-model.trim="areaCode"
                  placeholder="Area code"
                  style="
                    width: 30%;
                    outline: none;
                    border-left: none;
                    border-top: none;
                    border-bottom: none;
                    border-right: 1px solid rgba(87, 87, 87, 0.5);
                    border-top-right-radius: 0;
                    border-bottom-right-radius: 0;
                    padding: 10px;
                    padding-left: 25px;
                  "
                  inputmode="tel"
                  autocomplete="tel"
                  required
                  maxlength="8"
                  pattern="[0-9()+\()-]*"
                />
                <input
                  type="tel"
                  v-model.trim="landline"
                  placeholder="Enter Landline"
                  style="
                    width: 70%;
                    border: none;
                    outline: none;
                    padding: 10px 24px;
                  "
                  inputmode="tel"
                  autocomplete="tel"
                  required
                  maxlength="25"
                  pattern="[0-9()+\()-]*"
                />
              </div>
            </div>
            <div class="input-sections1">
              <div class="input-field-title">Customer Name</div>
              <input
                id="customer-type"
                type="text"
                class="input-field"
                style="width: 100%"
                v-model.trim="name"
                :placeholder="
                  windowWidth <= 1156 ? 'Customer Name' : 'Your Customer Name'
                "
                required
              />
            </div>
            <div class="input-sections1">
              <div class="input-field-title">Customer Type</div>
              <div
                style="
                  width: 100%;
                  border-radius: 13px;
                  display: flex;
                  flex-direction: row;
                  align-items: center;
                  background-color: white;
                "
              >
                <div
                  v-if="Object.keys(selectedCustomerType).length === 0"
                  @click="toggleCustomerTypeDropdown"
                  style="
                    position: absolute;
                    font-size: 80%;
                    color: #757575;
                    margin-left: 20px;
                    font-weight: 500;
                  "
                >
                  {{
                    windowWidth <= 1156
                      ? "Customer Type"
                      : "Select Your Customer Type"
                  }}
                </div>
                <select
                  v-model="selectedCustomerType"
                  ref="customerTypeDropdown"
                  title="Select Customer Type"
                  class="country-dropdown"
                  style="
                    width: 100% !important;
                    padding-bottom: 9px;
                    padding-top: 9px;
                    border: 1px solid rgba(17, 54, 90, 0.5) !important;
                    border-radius: 13px;
                    padding-left: 20px;
                  "
                >
                  <option value="" disabled selected>
                    Select Customer Type
                  </option>
                  <option
                    v-for="customerType in customertypes"
                    :value="customerType"
                    :key="customerType.name"
                    class="country-option"
                    style="padding-left: 20px"
                  >
                    {{ customerType.name }}
                  </option>
                </select>
              </div>
            </div>
          </div>
          <div class="frame-35815">
            <div class="input-sections1">
              <div class="input-field-title">Reference Number / Branch</div>
              <input
                type="text"
                placeholder="Reference Number"
                class="input-field"
                v-model.trim="referenceNumber"
              />
            </div>
            <div class="input-sections1" v-if="userType == null">
              <div class="input-field-title">
                Deliver To <span style="color: red">*</span>
              </div>
              <input
                class="input-field"
                style="width: 100%"
                type="text"
                v-model="deliveryEmail"
                :placeholder="
                  email == null ? 'Enter Delivery Email' : `Deliver to ${email}`
                "
                required
              />
            </div>
            <div
              class="input-sections1"
              v-if="
                userType == null
              "
            >
              <div class="input-field-title">
                Confirm Email <span style="color: red">*</span>
              </div>
              <input
                class="input-field"
                style="width: 100%"
                type="text"
                v-model="confirmDeliveryEmail"
                placeholder="Re-enter Delivery Email"
                required
              />
            </div>
          </div>
          <!-- <div class="frame-35817">
            <div
              class="frame-35807"
              style="
                background-color: #abe4e7;
                border-radius: 13px;
                padding: 7px;
              "
            >
              
            </div>
          </div> -->
          <div class="frame-35814" style="width: 100%">
            <div class="frame-35806">
              <div class="select-your-report">Select Your Report</div>
              <div
                style="
                  display: flex;
                  flex-direction: row;
                  width: 100%;
                  align-items: center;
                  justify-content: space-between;
                "
                title="The report will be compiled using the most recent data that is available."
              >
                <div class="frame-35630">
                  <input
                    type="checkbox"
                    id="fresh"
                    class="rectangle-10"
                    :checked="selectedReportType === 'FRESH'"
                    @click="
                      selectReportType(
                        'FRESH',
                        selectedCountry?.productOptions?.REPORT_COMPREHENSIVE
                          ?.FRESH
                      )
                    "
                  />
                  <label for="remember-me" class="remember-me"
                    >Fresh Report</label
                  >
                </div>
                <div
                  class="in-8-hrs"
                  v-if="Object.keys(this.selectedCountry).length > 0"
                >
                  in
                  {{
                    selectedCountry !== null &&
                    selectedCountry?.productOptions?.REPORT_COMPREHENSIVE?.FRESH
                      ?.eta_hours
                  }}
                  hr{{
                    selectedCountry !== null &&
                    selectedCountry?.productOptions?.REPORT_COMPREHENSIVE?.FRESH
                      ?.eta_hours > 1
                      ? "s"
                      : ""
                  }}
                </div>
              </div>
            </div>
          </div>
          <div
            style="width: 100%; display: flex; flex-direction: column"
            v-if="
              userType == null ||
              !userDetails?.includes('POSTPAID_ORDERS')
            "
          >
            <div class="order-total">
              <span>Order Total</span>
              <div class="all-currency-wrapper">
                <span class="currency-title">Currency</span>
                <select v-model="selectedCurrency" class="currency-dropdown">
                  <option
                    v-for="currency in currencies"
                    :value="currency"
                    :key="currency.code"
                    class="country-option"
                  >
                    {{ currency.code.toUpperCase() }} - {{ currency.name }}
                  </option>
                </select>
              </div>
            </div>
            <div class="USD-wrapper">
              <div class="USD" style="font-weight: 600">
                <span
                  >Total {{ selectedCurrency.code.toUpperCase() }}
                  <span
                    style="font-size: 12px; font-weight: 500"
                    v-if="selectedCurrency.code !== 'usd'"
                    >( 1$ = {{ convertedRate?.toFixed(2) }}
                    {{ selectedCurrency.code.toUpperCase() }})</span
                  ></span
                ><span>
                  {{
                    Object.keys(this.selectedCountry).length > 0
                      ? convertedTotal?.toFixed(2)
                      : 0
                  }}</span
                >
              </div>
            </div>
          </div>
          <div class="notes">
            <div class="note-title">
              <span>
                <span style="font-weight: 600">Note: </span>
                <span class="note-text">
                  Please note that our reports do not cover Financial
                  Institutions or any firms involved in remittances, gambling,
                  foreign exchange, money transfer services, or any other
                  financial services or institutions. By placing an order, you
                  acknowledge and agree to these exclusions.
                </span>
              </span>
            </div>
          </div>
        </div>
        <div class="frame-35647">
          <div
            class="order-btn"
            :style="[
              { backgroundColor: createButtonColor },
              { color: createButtonTextColor },
              {
                order:
                  userType !== null
                    ? 1
                    : 2,
              },
            ]"
            :disabled="isOrderButtonDisabled"
            v-on:click.prevent="disabled === false ? handleButtonClick() : none"
          >
            {{
              userType !== null
                ? "Checkout"
                : "Create"
            }}
          </div>
          <div
            class="cancel-btn"
            :style="[
              {
                opacity:
                  userType !== null
                    ? cancelButtonColor
                    : '1',
              },
              {
                backgroundColor:
                  userType !== null
                    ? '#26BBC2'
                    : 'white',
              },
              {
                order:
                  userType !== null
                    ? 2
                    : 1,
              },
            ]"
            :disabled="
              userType !== null
                ? isOrderButtonDisabled
                : none
            "
            v-on:click.prevent="handleButtonClick1"
          >
            {{
              userType !== null
                ? "Add To Cart"
                : "Cancel"
            }}
          </div>
        </div>
      </div>
    </div>
  </div>
  <Toastify ref="toastContainer" />
  <OrderConfirmationModal
    v-if="showOrderConfirmationModal"
    :selectedCompanyName="orderInfo?.companyName"
    :reportDetails="orderInfo?.reportDetails"
    :userEmail="orderInfo?.userEmail"
    @close="hideOrderConfirmation()"
  />
</template>
<script>
import {
  CreateOrder,
  AllCountries,
  AllCurrencies,
  FileUpload,
  AddToCart,
} from "@/api/APIs";
import VueCookies from "vue-cookies";
import Toastify from "@/components/Toastify.vue";
import OrderConfirmationModal from "@/views/Modals/OrderConfirmation.vue";
import { mapActions, mapGetters } from "vuex";

export default {
  name: "ManualOrderModal",
  components: {
    Toastify,
    OrderConfirmationModal,
  },
  props: {
    hideManualOrderModal: {
      type: Function,
      required: true,
    },
    selectedCountryData: {
      type: Object,
      required: true,
    },
    searchedCompanyName: {
      type: String,
      required: true,
    },
  },
  data() {
    return {
      uploadedFile: null,
      selectedReportDetails: {
        eta_hours: 5,
        purchase_credits: 75,
        purchase_usd: 75,
      },
      uploadedFileName: "",
      selectedCompanyName: this.searchedCompanyName,
      selectedReportType: "FRESH",
      countryId: "",
      name: "",
      email: VueCookies.get("email"),
      phone: "",
      landline: "",
      areaCode: "",
      additionalAddress: "",
      additionalInformation: "",
      deliveryEmail: VueCookies.get("email"),
      confirmDeliveryEmail: "",
      referenceNumber: "",
      fileKey: "",
      includeReferenceNumber: false,
      selectedCountry:
        Object.keys(this.selectedCountryData).length > 0
          ? this.selectedCountryData
          : {},
      selectedCountryCode:
        Object.keys(this.selectedCountryData).length > 0
          ? this.selectedCountryData
          : {},
      currencies: [],
      selectedCurrency: {
        id: 1,
        name: "US Dollar",
        code: "usd",
      },
      customertypes: [
        { name: "BANK" },
        { name: "IMPORTER" },
        { name: "EXPORTER" },
      ],
      selectedCustomerType: {},
      countries: [],
      filteredCountries: [],
      showResultsFlag: false,
      currencyValue: null,
      windowWidth: window.innerWidth,
      userType: VueCookies.get("user-type")
        ? VueCookies.get("user-type")
        : null,
      showOrderConfirmationModal: false,
      orderInfo: null,
      disabled: false,
    };
  },
  mounted() {
    window.addEventListener("resize", this.handleResize);
    if (this.selectedReportType === "FRESH") {
      this.selectReportType(
        "FRESH",
        this.selectedCountry?.productOptions?.REPORT_COMPREHENSIVE?.FRESH
      );
    }
    this.fetchCountryList();
    this.fetchCurrenciesList();
    this.fetchAPIData();
  },
  beforeUnmount() {
    window.removeEventListener("resize", this.handleResize);
  },
  computed: {
    ...mapGetters(["userDetails"]),
    convertedTotal() {
      const orderTotalUSD = this.getOrderTotal();

      if (this.selectedCurrency.code === "usd") {
        return orderTotalUSD;
      } else {
        const conversionRate = this.currencyValue[this.selectedCurrency.code];
        return orderTotalUSD * conversionRate;
      }
    },
    convertedRate() {
      const orderTotalUSD = this.getOrderTotal();

      if (this.selectedCurrency.code === "usd") {
        return orderTotalUSD;
      } else {
        const conversionRate = this.currencyValue[this.selectedCurrency.code];
        return conversionRate;
      }
    },
    showToast() {
      return this.$refs.toastContainer.addToast(
        "Please fill all the mandatory fields to place an order.",
        { type: "error" }
      );
    },
    isOrderButtonDisabled() {
      if (
        this.userType !== null
      ) {
        if (
          this.additionalAddress !== "" &&
          this.selectedCompanyName !== "" &&
          this.selectedCountry.name !== undefined
        ) {
          return true;
        }
      } else if (
        this.additionalAddress !== "" &&
        this.selectedCompanyName !== "" &&
        this.selectedCountry.name !== undefined &&
        this.deliveryEmail !== null &&
        /^[^\s@]+@[^\s@]+\.[^\s@]+$/.test(this.deliveryEmail) &&
        /^[^\s@]+@[^\s@]+\.[^\s@]+$/.test(this.confirmDeliveryEmail)
      ) {
        return true;
      }

      return false;
    },
    createButtonColor() {
      if (
        this.userType == null
      ) {
        return this.isOrderButtonDisabled ? "#26BBC2" : "#a2dfe3";
      } else {
        return this.isOrderButtonDisabled ? "white" : "#f4f5f7";
      }
    },
    createButtonTextColor() {
      return this.isOrderButtonDisabled ? "#04162D" : "#969da7";
    },
    cancelButtonColor() {
      return this.isOrderButtonDisabled ? "1" : "0.5";
    },
    cancelButtonTextColor() {
      return this.isOrderButtonDisabled ? "#04162D" : "#11365a";
    },
  },
  methods: {
    ...mapActions(["updateCartCount"]),
    updateSelectedCountry(event) {
      this.selectedCountry = {
        ...this.selectedCountry,
        name: event.target.value,
      };
      this.filterCountries();
    },
    handleButtonClick() {
      if (
        this.userType !== null &&
        this.isOrderButtonDisabled
      ) {
        this.CartCheckout();
      } else if (!this.isOrderButtonDisabled) {
        this.showToast;
      } else {
        this.placeOrder();
      }
    },
    async CartCheckout() {
      await this.AddToCart(true);
    },
    handleButtonClick1() {
      if (
        this.userType !== null &&
        this.isOrderButtonDisabled
      ) {
        this.AddToCart(false);
      } else if (
        this.userType !== null
      ) {
        this.hideManualOrderModal();
      } else if (this.userType == null) {
        this.hideManualOrderModal();
      } else if (!this.isOrderButtonDisabled) {
        this.showToast;
      }
    },
    toggleCustomerTypeDropdown() {
      this.$refs.customerTypeDropdown.focus();
    },
    selectReportType(type, selectedReport) {
      this.selectedReportType = type;
      this.selectedReportDetails = selectedReport;
    },
    handleResize() {
      this.windowWidth = window.innerWidth;
    },
    isWindows() {
      return /Windows/.test(window.navigator.userAgent);
    },
    async fetchAPIData() {
      try {
        const response = await fetch(
          "https://cdn.jsdelivr.net/npm/@fawazahmed0/currency-api@latest/v1/currencies/usd.json"
        );
        if (response.ok) {
          const data = await response.json();
          this.currencyValue = data.usd;
        } else {
          console.error("Failed to fetch API data:", response.statusText);
        }
      } catch (error) {
        console.error("Error occurred while fetching API data:", error);
      }
    },
    async fetchCurrenciesList() {
      try {
        const response = await AllCurrencies();
        this.currencies = await response.allCurrencies;
      } catch (error) {
        this.loginError = "Error occurred during login.";
        console.error(error);
      }
    },
    getOrderTotal() {
      if (this.selectedReportType === "FRESH") {
        return this.selectedCountry?.productOptions?.REPORT_COMPREHENSIVE?.FRESH
          ?.purchase_usd;
      }
      return "";
    },
    async handleFileUpload(event) {
      const file = event.target.files[0];

      if (!file) {
        return;
      }

      const allowedTypes = [
        "application/pdf",
        "application/msword",
        "application/rtf",
        "application/vnd.oasis.opendocument.text",
      ];

      if (
        !allowedTypes.includes(file.type) &&
        !file.type.startsWith("image/")
      ) {
        event.target.value = "";
        return this.$refs.toastContainer.addToast(
          "Invalid file type. Please upload a valid file.",
          { type: "error" }
        );
      }

      this.uploadedFile = file;
      this.uploadedFileName = file.name;

      const formData = new FormData();
      formData.append("file", file);

      try {
        const response = await FileUpload(formData);
        if (
          response &&
          response.message === "SUCCESS" &&
          response.fileResults.length > 0
        ) {
          const fileResult = response.fileResults[0];
          this.fileKey = fileResult.fileKey;
        }
      } catch (error) {
        this.loginError = "Error occurred during upload.";
        console.error(error);
      }
    },
    getFirstDialCode(dialCodeString) {
      return dialCodeString.split(",")[0];
    },
    formatPhoneNumber() {
      this.phone = this.phone.replace(/\D/g, "");
    },
    async fetchCountryList() {
      try {
        const response = await AllCountries(false);
        this.countries = await response.allCountries;
      } catch (error) {
        this.loginError = "Error occurred during login.";
        console.error(error);
      }
    },
    filterCountries() {
      this.showResultsFlag = true;
      this.filteredCountries = this.countries.filter((country) =>
        country.name.toLowerCase().includes(this.selectedCountry?.name)
      );
    },
    selectCountry(country) {
      this.selectedCountry = country;
      this.filteredCountries = [];
    },
    showResults() {
      this.showResultsFlag = true;
      this.filteredCountries = this.countries;
      document.addEventListener("click", this.closeResultsOnClickOutside);
    },
    closeResultsOnClickOutside(event) {
      const searchInput = this.$refs.searchInput;
      const countryResults = this.$refs.countryResults;
      if (
        searchInput &&
        !searchInput.contains(event.target) &&
        countryResults &&
        !countryResults.contains(event.target)
      ) {
        this.showResultsFlag = false;
        document.removeEventListener("click", this.closeResultsOnClickOutside);
      }
    },
    isCountrySelected() {
      return this.countries.some(
        (country) => JSON.stringify(country) === JSON.stringify(this.selectedCountry)
      );
    },
    async AddToCart(checkout) {
      if (!this.isCountrySelected()) {
        return this.$refs.toastContainer.addToast(
          "Please select a valid country from the dropdown.",
          { type: "error" }
        );
      }

      if (
        !this.selectedCompanyName ||
        this.selectedCompanyName.trim().length === 0
      ) {
        return this.$refs.toastContainer.addToast(
          "Company name cannot be empty or contain only blank spaces.",
          { type: "error" }
        );
      }

      const invalidAddressTexts = ["na", "n/a", "not applicable", "-"];
      const isInvalidAddress =
        this.additionalAddress &&
        invalidAddressTexts.includes(
          this.additionalAddress.trim().toLowerCase()
        );
      const addressWordCount =
        this.additionalAddress &&
        this.additionalAddress.trim().split(/\s+/).length;

      if (
        isInvalidAddress ||
        addressWordCount < 4 ||
        this.additionalAddress.trim().length === 0
      ) {
        return this.$refs.toastContainer.addToast(
          "Please enter a valid additional address with at least 4 words.",
          { type: "error" }
        );
      }

      const firstDialCode =
        this.phone !== "" &&
        this.getFirstDialCode(this.selectedCountryCode.dialCode);

      const phoneNumberLengths =
        this.selectedCountryCode && this.selectedCountryCode.phoneLength
          ? this.selectedCountryCode.phoneLength
              .split(",")
              .map((length) => parseInt(length.trim()))
          : [];
      if (
        this.phone !== "" &&
        this.phone.trim().length === 0 &&
        !phoneNumberLengths.includes(this.phone.length)
      ) {
        this.isLoading = false;
        return this.$refs.toastContainer.addToast(
          `Phone number length should be ${this.selectedCountryCode?.phoneLength} digits`,
          { type: "error" }
        );
      }

      if (this.landline !== "" && this.landline.trim().length === 0) {
        return this.$refs.toastContainer.addToast(
          `Landline number cannot contain only blank spaces.`,
          { type: "error" }
        );
      }

      if (this.landline !== "" && this.areaCode === "") {
        this.isLoading = false;
        return this.$refs.toastContainer.addToast(
          "Please enter the area code for the provided landline number.",
          { type: "error" }
        );
      }

      if (this.areaCode !== "" && this.areaCode.trim().length === 0) {
        return this.$refs.toastContainer.addToast(
          `Area code cannot contain only blank spaces.`,
          { type: "error" }
        );
      }

      if (this.referenceNumber && this.referenceNumber.trim().length > 0) {
        this.includeReferenceNumber = true;
      }

      const phoneNo =
        this.phone !== "" ? `+${firstDialCode}-${this.phone}` : "";
      const fullLandline =
        this.landline !== "" ? `${this.areaCode}-${this.landline}` : "";

      try {
        let address =
          this.clickedSearchResult?.additional_info?.business_address;
        if (!address) {
          address = "-";
        }

        const response = await AddToCart(
          this.selectedCompanyName,
          this.additionalAddress,
          this.selectedCountry?.id,
          this.deliveryEmail,
          phoneNo,
          fullLandline,
          this.selectedReportType,
          this.selectedReportDetails,
          this.additionalInformation,
          this.name,
          this.referenceNumber,
          this.includeReferenceNumber,
          this.selectedCustomerType?.name,
          this.selectedCurrency?.id,
          true,
          this.fileKey
        );
        if (response && response.message == "Added to cart") {
          this.hideManualOrderModal();
          this.isLoading = false;
          this.updateCartCount(response.cartSize);
          if (checkout) {
            this.$root.toggleCheckout();
          } else {
            this.hideManualOrderModal();
            this.$root.toggleCheckoutTooltip();
            return this.$refs.toastContainer.addToast("Added to cart", {
              type: "success",
            });
          }
        } else {
          this.isLoading = false;
          return this.$refs.toastContainer.addToast(
            "Something went wrong. Please try again later.",
            { type: "error" }
          );
        }
      } catch (error) {
        if (error.response && error.response.status !== 403) {
          return this.$refs.toastContainer.addToast(
            "Something went wrong. Please try again later.",
            { type: "error" }
          );
        }
      }
      this.$root.hideLoader();
    },
    async placeOrder() {
      this.disabled = true;
      if (!this.isCountrySelected()) {
        this.disabled = false;
        return this.$refs.toastContainer.addToast(
          "Please select a valid country from the dropdown.",
          { type: "error" }
        );
      }

      if (
        !this.selectedCompanyName ||
        this.selectedCompanyName.trim().length === 0
      ) {
        this.disabled = false;
        return this.$refs.toastContainer.addToast(
          "Company name cannot be empty or contain only blank spaces.",
          { type: "error" }
        );
      }

      const invalidAddressTexts = ["na", "n/a", "not applicable", "-"];
      const isInvalidAddress =
        this.additionalAddress &&
        invalidAddressTexts.includes(
          this.additionalAddress.trim().toLowerCase()
        );
      const addressWordCount =
        this.additionalAddress &&
        this.additionalAddress.trim().split(/\s+/).length;

      if (
        isInvalidAddress ||
        addressWordCount < 4 ||
        this.additionalAddress.trim().length === 0
      ) {
        this.disabled = false;
        return this.$refs.toastContainer.addToast(
          "Please enter a valid additional address with at least 4 words.",
          { type: "error" }
        );
      }

      const firstDialCode =
        this.phone !== "" &&
        this.getFirstDialCode(this.selectedCountryCode.dialCode);

      const phoneNumberLengths =
        this.selectedCountryCode && this.selectedCountryCode.phoneLength
          ? this.selectedCountryCode.phoneLength
              .split(",")
              .map((length) => parseInt(length.trim()))
          : [];

      if (this.deliveryEmail !== this.confirmDeliveryEmail) {
        this.isLoading = false;
        this.disabled = false;
        return this.$refs.toastContainer.addToast(
          "Delivery email and confirm email do not match.",
          { type: "error" }
        );
      }
      if (
        this.phone !== "" &&
        this.phone.trim().length === 0 &&
        !phoneNumberLengths.includes(this.phone.length)
      ) {
        this.isLoading = false;
        this.disabled = false;
        return this.$refs.toastContainer.addToast(
          `Phone number length should be ${this.selectedCountryCode?.phoneLength} digits`,
          { type: "error" }
        );
      }
      if (this.landline !== "" && this.landline.trim().length === 0) {
        this.disabled = false;
        return this.$refs.toastContainer.addToast(
          `Landline number cannot contain only blank spaces.`,
          { type: "error" }
        );
      }
      if (this.landline !== "" && this.areaCode === "") {
        this.isLoading = false;
        this.disabled = false;
        return this.$refs.toastContainer.addToast(
          "Please enter the area code for the provided landline number.",
          { type: "error" }
        );
      }

      if (this.areaCode !== "" && this.areaCode.trim().length === 0) {
        this.disabled = false;
        return this.$refs.toastContainer.addToast(
          `Area code cannot contain only blank spaces.`,
          { type: "error" }
        );
      }

      if (this.referenceNumber && this.referenceNumber.trim().length > 0) {
        this.includeReferenceNumber = true;
      }

      const phoneNo =
        this.phone !== "" ? `+${firstDialCode}-${this.phone}` : "";
      const fullLandline =
        this.landline !== "" ? `${this.areaCode}-${this.landline}` : "";

      try {
        let address =
          this.clickedSearchResult?.additional_info?.business_address;
        if (!address) {
          address = "-";
        }

        const response = await CreateOrder(
          this.selectedCompanyName,
          this.additionalAddress,
          this.selectedCountry?.id,
          this.deliveryEmail,
          phoneNo,
          fullLandline,
          this.selectedReportType,
          this.selectedReportDetails,
          this.additionalInformation,
          this.name,
          this.referenceNumber,
          this.includeReferenceNumber,
          this.selectedCustomerType?.name,
          this.selectedCurrency?.id,
          true,
          this.fileKey
        );
        if (response && response.message == "SUCCESS") {
          this.disabled = false;
          if (response?.result?.paymentRequired === true) {
            window.location.href = response?.result?.paymentLink;
          } else {
            const userEmail = VueCookies.get("user");
            this.orderInfo = {
              companyName: this.selectedCompanyName,
              reportDetails: this.selectedReportDetails,
              userEmail,
            };
            this.isLoading = false;
            this.showOrderConfirmationModal = true;
            return;
          }
          // this.viewOrderConfirmationModal();
          this.hideManualOrderModal();
        } else {
          this.disabled = false;
          return this.$refs.toastContainer.addToast(
            "Something went wrong. Please try again later.",
            { type: "error" }
          );
        }
      } catch (error) {
        this.disabled = false;
        if (error.response && error.response.status !== 403) {
          return this.$refs.toastContainer.addToast(
            "Something went wrong. Please try again later.",
            { type: "error" }
          );
        }
      }
      this.$root.hideLoader();
    },
    hideOrderConfirmation() {
      this.showOrderConfirmationModal = false;
      this.hideManualOrderModal();
    },
  },
};
</script>
<style scoped>
.modal,
.modal * {
  box-sizing: border-box;
}
.bg-b-lue {
  background: rgba(0, 0, 0, 8%);
  display: flex;
  align-items: center;
  justify-content: center;
  backdrop-filter: blur(10px);
  z-index: 9999;
}
.disabled {
  background-color: gray;
  cursor: not-allowed;
}
.flag-image {
  width: 20px;
  height: 15px;
}
.modal {
  background: #e7ebf0;
  border-radius: 24px;
  display: flex;
  flex-direction: column;
  gap: 0px;
  align-items: center;
  justify-content: flex-start;
  /* min-height: 560px; */
  position: relative;
  box-shadow: 0px 24px 24px 0px rgba(17, 54, 90, 0.2);
  overflow: hidden;
  width: 90%;
  max-height: 95%;
}
.frame-35811 {
  background: #11365a;
  padding: 15px 40px 15px 40px;
  display: flex;
  flex-direction: column;
  gap: 10px;
  align-items: center;
  justify-content: flex-start;
  align-self: stretch;
  flex-shrink: 0;
  position: relative;
  overflow: hidden;
}
.frame-35805 {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  align-self: stretch;
  flex-shrink: 0;
  position: relative;
}
.frame-35812 {
  display: flex;
  flex-direction: row;
  gap: 24px;
  align-items: center;
  justify-content: flex-start;
  flex-shrink: 0;
  position: relative;
}
.input-sections1 {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: center;
  gap: 10px;
  width: 32.5%;
}
.input-field-title {
  font-size: 14px;
  font-weight: 600;
  font-family: "Montserrat-Medium", sans-serif;
  text-align: left;
  width: 100%;
}
.create-a-manual-order {
  color: white;
  text-align: left;
  font-family: "Montserrat-Bold", sans-serif;
  font-size: 18px;
  font-weight: 600;
  position: relative;
}
.frame-35654 {
  padding: 10px 40px 10px 40px;
  display: flex;
  flex-direction: column;
  gap: 20px;
  align-items: center;
  justify-content: flex-start;
  align-self: stretch;
  flex-shrink: 0;
  /* height: 560px; */
  position: relative;
}
.frame-35656 {
  display: flex;
  flex-direction: column;
  gap: 20px;
  align-items: flex-start;
  justify-content: flex-start;
  align-self: stretch;
  flex: 1;
  position: relative;
}
.frame-35815 {
  display: flex;
  flex-direction: row;
  /* gap: 40px; */
  align-items: flex-start;
  justify-content: space-between;
  align-self: stretch;
  flex-shrink: 0;
  position: relative;
}
.frame-35739 {
  display: flex;
  flex-direction: column;
  gap: 8px;
  align-items: flex-start;
  justify-content: center;
  flex: 1;
  position: relative;
  width: 100%;
}
.frame-35559 {
  background: #ffffff;
  border-radius: 13px;
  border-style: solid;
  border-color: rgba(17, 54, 90, 0.5);
  border-width: 1px;
  /* padding: 10px 24px 10px 24px; */
  display: flex;
  flex-direction: row;
  gap: 10px;
  align-items: center;
  justify-content: flex-start;
  align-self: stretch;
  flex-shrink: 0;
  min-width: 150px;
  position: relative;
  /* box-shadow: 0px 14px 44px 0px rgba(0, 0, 0, 0.25); */
}
.enter-company-name {
  color: #3e4855;
  text-align: center;
  font-family: "Montserrat-Regular", sans-serif;
  font-size: 14px;
  font-weight: 400;
  position: relative;
}
.frame-35740 {
  display: flex;
  flex-direction: column;
  gap: 8px;
  align-items: flex-start;
  justify-content: center;
  flex: 1;
  width: 100%;
  position: relative;
}
.frame-355592 {
  background: #ffffff;
  border-radius: 13px;
  border-style: solid;
  border-color: rgba(17, 54, 90, 0.5);
  border-width: 1px;
  padding: 10px 24px 10px 24px;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  align-self: stretch;
  flex-shrink: 0;
  height: 40px;
  min-width: 150px;
  position: relative;
  /* box-shadow: 0px 14px 44px 0px rgba(0, 0, 0, 0.25); */
}
.frame-35818 {
  display: flex;
  flex-direction: row;
  gap: 10px;
  align-items: center;
  justify-content: flex-start;
  flex-shrink: 0;
  position: relative;
}
.sri-lanka-lk {
  flex-shrink: 0;
  width: 14px;
  height: 11px;
  position: relative;
  overflow: hidden;
}
.vector {
  width: 100%;
  height: 100%;
  position: absolute;
  right: 0%;
  left: 0%;
  bottom: 0%;
  top: 0%;
  overflow: visible;
}
.sri-lanka {
  color: #3e4855;
  text-align: center;
  font-family: "Montserrat-Regular", sans-serif;
  font-size: 16px;
  font-weight: 400;
  position: relative;
}
.vector-1 {
  flex-shrink: 0;
  width: 10px;
  height: 5px;
  position: relative;
  overflow: visible;
}
.frame-35816 {
  display: flex;
  flex-direction: row;
  gap: 40px;
  align-items: flex-start;
  justify-content: flex-start;
  align-self: stretch;
  flex-shrink: 0;
  position: relative;
}
.frame-35741 {
  display: flex;
  flex-direction: column;
  gap: 8px;
  align-items: flex-start;
  justify-content: center;
  flex: 1;
  position: relative;
}
.frame-355593 {
  background: #ffffff;
  border-radius: 13px;
  border-style: solid;
  border-color: rgba(17, 54, 90, 0.5);
  border-width: 1px;
  padding: 17px 24px 17px 24px;
  display: flex;
  flex-direction: row;
  gap: 10px;
  align-items: flex-start;
  justify-content: flex-start;
  align-self: stretch;
  flex-shrink: 0;
  height: 80px;
  min-width: 150px;
  position: relative;
  /* box-shadow: 0px 14px 44px 0px rgba(0, 0, 0, 0.25); */
}
.enter-address {
  color: #3e4855;
  text-align: center;
  font-family: "Montserrat-Regular", sans-serif;
  font-size: 14px;
  font-weight: 400;
  position: relative;
}
.frame-35745 {
  display: flex;
  flex-direction: column;
  gap: 8px;
  align-items: flex-start;
  justify-content: center;
  flex: 1;
  position: relative;
}
.provide-more-information-about-the-company {
  color: #3e4855;
  text-align: center;
  font-family: "Montserrat-Regular", sans-serif;
  font-size: 14px;
  font-weight: 400;
  position: relative;
}
.frame-35744 {
  display: flex;
  flex-direction: row;
  gap: 40px;
  align-items: flex-start;
  justify-content: flex-start;
  align-self: stretch;
  flex-shrink: 0;
  position: relative;
}
.frame-35819 {
  display: flex;
  flex-direction: column;
  gap: 8px;
  align-items: flex-start;
  justify-content: center;
  flex-shrink: 0;
  position: relative;
}
.frame-355594 {
  background: #ffffff;
  border-radius: 13px;
  border-style: solid;
  border-color: rgba(17, 54, 90, 0.5);
  border-width: 1px;
  padding: 10px 24px 10px 24px;
  display: flex;
  flex-direction: row;
  gap: 10px;
  align-items: center;
  justify-content: flex-start;
  flex-shrink: 0;
  width: 565px;
  height: 40px;
  min-width: 150px;
  position: relative;
  /* box-shadow: 0px 14px 44px 0px rgba(0, 0, 0, 0.25); */
}
.enter-customer-name {
  color: #3e4855;
  text-align: center;
  font-family: "Montserrat-Regular", sans-serif;
  font-size: 14px;
  font-weight: 400;
  position: relative;
}
.frame-35820 {
  display: flex;
  flex-direction: column;
  gap: 8px;
  align-items: flex-start;
  justify-content: center;
  flex-shrink: 0;
  position: relative;
}
.frame-35560 {
  background: #ffffff;
  border-radius: 13px;
  border-style: solid;
  border-color: rgba(17, 54, 90, 0.5);
  border-width: 1px;
  padding: 10px 24px 10px 24px;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  flex-shrink: 0;
  width: 565px;
  height: 40px;
  min-width: 150px;
  position: relative;
  /* box-shadow: 0px 14px 44px 0px rgba(0, 0, 0, 0.25); */
}
.select-customer-type {
  color: #3e4855;
  text-align: center;
  font-family: "Montserrat-Regular", sans-serif;
  font-size: 14px;
  font-weight: 400;
  position: relative;
}
.frame-35817 {
  display: flex;
  flex-direction: row;
  gap: 40px;
  align-items: flex-start;
  justify-content: flex-start;
  align-self: stretch;
  flex-shrink: 0;
  position: relative;
}
.frame-35807 {
  display: flex;
  flex-direction: column;
  gap: 8px;
  align-items: flex-start;
  justify-content: flex-start;
  flex: 1;
  position: relative;
}
.reference-number-optional {
  color: #3e4855;
  text-align: center;
  font-family: "Montserrat-Regular", sans-serif;
  font-size: 14px;
  font-weight: 400;
  opacity: 0.5;
  position: relative;
}
.frame-35627 {
  display: flex;
  flex-direction: row;
  gap: 16px;
  align-items: center;
  justify-content: flex-start;
  flex-shrink: 0;
  position: relative;
}
.rectangle-10 {
  background: #ffffff;
  border-radius: 4px;
  border-style: solid;
  border-color: #18c3df !important;
  border-width: 2px;
  flex-shrink: 0;
  width: 18px;
  height: 18px;
  position: relative;
  cursor: pointer;
  appearance: none;
}
.rectangle-10:checked {
  background-color: #18c3df !important;
}
.rectangle-10:checked:after {
  content: "\2713";
  color: white !important;
  font-size: 20px;
  position: absolute;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
}
.include-in-the-final-report {
  color: var(--text-dark, #04162d);
  text-align: center;
  font-family: "Montserrat-Regular", sans-serif;
  font-size: 16px;
  line-height: 150%;
  font-weight: 600;
  position: relative;
}
.frame-35743 {
  display: flex;
  flex-direction: column;
  gap: 8px;
  align-items: flex-start;
  justify-content: flex-start;
  align-self: stretch;
  flex: 1;
  position: relative;
}
.upload-proforma-invoice-optional {
  color: var(--text-dark, #04162d);
  text-align: left;
  font-family: "Montserrat-SemiBold", sans-serif;
  font-size: 16px;
  line-height: 150%;
  font-weight: 600;
  position: relative;
}
.frame-357442 {
  display: flex;
  flex-direction: row;
  gap: 8px;
  align-items: center;
  justify-content: flex-start;
  flex-shrink: 0;
  position: relative;
}
.frame-355595 {
  background: #ffffff;
  border-radius: 13px;
  border-style: solid;
  border-color: rgba(17, 54, 90, 0.5);
  border-width: 1px;
  padding: 10px 24px 10px 24px;
  display: flex;
  flex-direction: row;
  gap: 10px;
  align-items: center;
  justify-content: flex-start;
  flex-shrink: 0;
  min-width: 150px;
  position: relative;
  /* box-shadow: 0px 14px 44px 0px rgba(0, 0, 0, 0.25); */
}
.choose-file {
  color: #3e4855;
  text-align: center;
  font-family: "Montserrat-Regular", sans-serif;
  font-size: 14px;
  font-weight: 400;
  position: relative;
}
.no-file-chosen {
  color: #3e4855;
  text-align: center;
  font-family: "Montserrat-Regular", sans-serif;
  font-size: 14px;
  font-weight: 400;
  position: relative;
}
.frame-35814 {
  display: flex;
  flex-direction: row;
  gap: 40px;
  align-items: flex-start;
  justify-content: flex-start;
  align-self: stretch;
  flex-shrink: 0;
  position: relative;
}
.frame-35806 {
  display: flex;
  flex-direction: column;
  gap: 10px;
  align-items: flex-start;
  justify-content: flex-start;
  flex: 1;
  position: relative;
}
.select-your-report {
  color: var(--text-dark, #04162d);
  text-align: center;
  font-family: "Montserrat-SemiBold", sans-serif;
  font-size: 16px;
  line-height: 150%;
  font-weight: 600;
  position: relative;
}
.frame-35664 {
  display: flex;
  flex-direction: row;
  gap: 16px;
  align-items: center;
  justify-content: flex-start;
  flex-shrink: 0;
  position: relative;
}
.frame-35630 {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-start;
  align-self: stretch;
  flex-shrink: 0;
  position: relative;
  gap: 40px;
}
.fresh-report {
  color: var(--text-dark, #04162d);
  text-align: center;
  font-family: "Montserrat-Regular", sans-serif;
  font-size: 16px;
  line-height: 150%;
  font-weight: 400;
  position: relative;
}
.in-8-hrs {
  color: var(--text-dark, #04162d);
  text-align: center;
  font-family: "Montserrat-Italic", sans-serif;
  font-size: 14px;
  line-height: 150%;
  font-weight: 400;
  font-style: true;
  position: relative;
}
.frame-35753 {
  display: flex;
  flex-direction: column;
  gap: 10px;
  align-items: flex-start;
  justify-content: flex-start;
  position: relative;
  width: 50%;
}
.frame-35631 {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  align-self: stretch;
  flex-shrink: 0;
  position: relative;
}
.frame-356642 {
  display: flex;
  flex-direction: row;
  gap: 16px;
  align-items: center;
  justify-content: flex-start;
  flex: 1;
  position: relative;
}
.delivery-to-samplemail-gmail-com-span {
  color: #3e4855;
  font-family: "Montserrat-Regular", sans-serif;
  font-size: 14px;
  line-height: 150%;
  font-weight: 400;
}
.delivery-to-samplemail-gmail-com-span2 {
  color: #3e4855;
  font-family: "Montserrat-Regular", sans-serif;
  font-size: 14px;
  line-height: 150%;
  font-weight: 400;
  text-decoration: underline;
}
.frame-35647 {
  display: flex;
  flex-direction: row;
  gap: 24px;
  align-items: center;
  justify-content: flex-start;
  align-self: stretch;
  flex-shrink: 0;
  position: relative;
  margin-bottom: 20px;
}
.order-btn {
  /* background: var(--main-color, #26bbc2) !important; */
  padding: 10px 24px 10px 24px !important;
  align-self: stretch !important;
  width: 100%;
  font-weight: 600;
  border-radius: 13px;
  font-family: "Montserrat-Regular", sans-serif;
  cursor: pointer;
  color: white;
}
.cancel-btn {
  padding: 10px 24px 10px 24px !important;
  align-self: stretch !important;
  background-color: white;
  /* border: 1px solid #1e1e1e; */
  width: 100%;
  font-weight: 600;
  border-radius: 13px;
  font-family: "Montserrat-Regular", sans-serif;
  cursor: pointer;
}
.input-field {
  border-radius: 13px;
  height: auto !important;
  box-shadow: none;
  width: 100%;
  outline: none;
  border: none;
  padding: 10px 25px;
  border: 1px solid rgba(17, 54, 90, 0.5);
  margin: 0;
}
.country-results {
  width: 100%;
  max-height: 200px;
  display: flex;
  flex-direction: column;
  align-items: center;
  position: absolute;
  background-color: white;
  border-radius: 15px;
  overflow-y: auto;
  top: 110%;
  z-index: 9999;
  box-shadow: 0px 16px 16px 0px rgba(20, 45, 77, 0.1);
  scrollbar-width: none;
  -ms-overflow-style: none;
}
.country-results div {
  width: 100%;
  display: flex;
  position: relative;
  align-items: flex-start;
  padding: 5px 0 5px 20px;
  text-align: left;
  font-size: 14px;
}
.country-results div:hover {
  background-color: #dee9f2;
}
.user-details-wrapper {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
}
.country-dropdown {
  width: 35% !important;
  outline: none;
  border: none !important;
}
.additional-address {
  width: 100%;
  font-family: "Montserrat-Regular", sans-serif;
  box-shadow: none;
  border-radius: 13px;
  outline: none;
  margin: 0;
}
textarea {
  height: 65px;
}
.order-total {
  font-size: 16px;
  font-weight: 600;
  font-family: "Montserrat-Regular", sans-serif;
  text-align: left;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  width: 100%;
}
.USD-wrapper {
  display: flex;
  width: 100%;
  flex-direction: column;
  align-items: center;
  justify-content: flex-start;
  gap: 10px;
  margin-top: 20px;
}
.USD {
  width: 100%;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  font-size: 18px;
  font-weight: 500;
  font-family: "Montserrat-Regular", sans-serif;
}
.currency-title {
  font-size: 14px;
  font-weight: 500;
  font-family: "Montserrat-Regular", sans-serif;
}
.all-currency-wrapper {
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
  align-items: center;
  text-align: right;
  gap: 15px;
}
.currency-dropdown {
  width: 22% !important;
  outline: none;
  border: 1px solid rgba(87, 87, 87, 0.5);
  border-radius: 13px;
  padding: 10px;
}
.remember-me {
  font-family: "Montserrat-Medium", sans-serif;
  font-size: 14px;
}
.notes {
  padding: 10px 20px;
  border-radius: 16px;
  background: white;
  box-shadow: -4px -4px 8px 0px #fff, 4px 4px 8px 0px rgba(0, 0, 0, 0.25);
  width: 100%;
  /* margin-top: 15px; */
}
.note-title {
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  justify-content: flex-start;
  font-family: "Montserrat-Light", sans-serif;
  text-align: left;
  gap: 7px;
  font-weight: 500;
  font-size: small;
}
.note-text {
  text-align: left;
  padding-left: 5px;
  font-family: "Montserrat-Regular", sans-serif;
  font-size: 13px;
  line-height: 150%;
}

@media only screen and (max-width: 767px) {
  .bg-b-lue {
    align-items: flex-end;
  }
  .notes {
    padding: 8px;
    border-radius: 8px;
  }
  .modal {
    border-top-left-radius: 24px;
    border-top-right-radius: 24px;
    border-bottom-left-radius: 0;
    border-bottom-right-radius: 0;
    padding-bottom: 5px;
    width: 95%;
  }
  .frame-35811 {
    padding: 5px 15px;
  }
  .note-title {
    font-size: 12px;
  }
  .input-sections1 {
    /* flex-direction: column; */
    width: 100%;
  }
  .create-a-manual-order {
    font-size: 14px;
  }
  .frame-35654 {
    max-height: 89vh;
    overflow-y: auto;
    overflow-x: hidden;
    padding: 10px 15px;
    scrollbar-width: none;
    -ms-overflow-style: none;
  }
  svg {
    width: 25px;
    height: 25px;
  }
  .frame-35656 {
    gap: 10px;
  }
  .frame-35815,
  .frame-35816,
  .frame-35817,
  .frame-35814 {
    flex-direction: column;
    gap: 10px;
  }
  .frame-35744 {
    gap: 10px;
  }
  .input-field,
  input[type="tel" i] {
    margin: 0;
    padding: 5px 20px !important;
    font-size: 16px;
  }
  .frame-35559 input {
    margin: 0;
    padding: 5px 20px !important;
    height: auto;
    font-size: 16px;
  }
  .frame-35739,
  .frame-35740,
  .additional-address,
  .frame-35807 {
    width: 100% !important;
    font-size: 16px;
  }
  textarea {
    height: 60px;
    margin: 0;
  }
  .frame-35744 div div {
    margin-left: 20px !important;
    text-align: left;
    width: fit-content !important;
  }
  .input-group,
  .frame-35753 {
    width: 100% !important;
  }
  .frame-35744 div select {
    padding: 5px 20px !important;
  }
  .country-results div,
  .remember-me,
  .in-8-hrs,
  .all-currency-wrapper {
    font-size: 14px;
  }
  .rectangle-10 {
    width: 14px;
    height: 14px;
  }
  .upload-proforma-invoice-optional,
  .select-your-report {
    font-size: 14px;
  }
  .frame-35743 {
    padding: 5px 15px !important;
  }
  input[type="file" i] {
    padding: 8px 0 !important;
  }
  .order-total {
    margin-top: 20px;
    flex-direction: column;
    gap: 15px;
    align-items: flex-start;
  }
  .all-currency-wrapper {
    width: 100%;
    justify-content: space-between;
  }
  .frame-35630 {
    gap: 20px;
  }
  .currency-dropdown {
    width: 30% !important;
    height: 27.22px;
    padding: 0 10px;
  }
  .rectangle-10:checked:after {
    content: none;
    color: white !important;
    font-size: 15px;
    position: absolute;
    left: 50%;
    top: 50%;
    transform: translate(-50%, -50%);
  }
  .country-dropdown {
    /* height: 27.22px; */
    padding: 0 10px;
  }
  .frame-35806 {
    width: 100%;
  }
}
@media only screen and (min-width: 768px) and (max-width: 1156px) and (max-height: 800px) {
  .frame-35654 {
    max-height: 77vh;
    overflow-y: auto;
    overflow-x: hidden;
    scrollbar-width: none;
  }
  .notes {
    padding: 12px;
  }
  .note-title {
    font-size: 16px;
  }
}
@media only screen and (min-width: 1156px) and (max-height: 800px) {
  .frame-35654 {
    max-height: 77vh;
    overflow-y: auto;
    overflow-x: hidden;
    scrollbar-width: none;
  }
}
</style>
