<template>
  <div class="popup bg-b-lue">
    <div class="frame-35628">
      <div class="title">Add Delivery Emails</div>
      <div class="cta-wrapper" v-if="!addEnabled">
        <select v-model="bankName" class="input-field">
          <option value="null" disabled>Select Bank</option>
          <option
            v-for="(bank, index) in banksList"
            :value="bank?.id"
            :key="index"
            class="country-option"
          >
            {{ bank?.name }}
          </option>
        </select>
        <select
          v-model="BranchName"
          class="input-field"
          :disabled="bankName === null"
        >
          <option value="null" disabled>
            {{ branchList === null ? "No Branches to show" : "Select Branch" }}
          </option>
          <option
            v-for="(branchDetails, index) in branchList"
            :value="branchDetails?.id"
            :key="index"
            class="country-option"
          >
            {{ branchDetails?.branchName }}
          </option>
        </select>
      </div>
      <div class="add-members-wrapper" v-if="addEnabled">
        <input
          type="email"
          placeholder="Enter Email"
          class="invitee-email"
          autocomplete="off"
          id="inviteeEmail"
          v-model="inviteeEmail"
          for="inviteeEmail"
        />
        <div class="add-button" @click="addInvitee">
          <span>Add</span>
        </div>
      </div>
      <div class="invitees-wrapper">
        <div class="invitees-title">
          {{ addEnabled ? "You are adding:" : "Existing delivery emails:" }}
        </div>
        <div class="invitees-list invitees-list-height">
          <div
            class="table-wrapper"
            v-if="addEnabled ? invitees?.length > 0 : existingUsers?.length > 0"
          >
            <table
              id="searchResultTable"
              v-if="
                addEnabled ? invitees?.length > 0 : existingUsers?.length > 0
              "
            >
              <tbody>
                <tr
                  v-for="(invitee, index) in addEnabled
                    ? invitees
                    : existingUsers"
                  :style="hoveredRow2 === index ? hoverStyle : {}"
                  :key="index"
                >
                  <td>
                    <input
                      v-if="invitee.isEditing"
                      v-model="invitee.updatedEmail"
                      type="email"
                      class="edit-email"
                    />
                    <span v-else>{{
                      addEnabled ? invitee : invitee.deliveryEmail
                    }}</span>
                  </td>
                  <td>
                    <div
                      class="remove-invitee"
                      @click="removeInvitee(index)"
                      v-if="addEnabled"
                    >
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        width="18"
                        height="18"
                        viewBox="0 0 18 18"
                        fill="none"
                      >
                        <path
                          d="M10.9425 6L9 7.9425L7.0575 6L6 7.0575L7.9425 9L6 10.9425L7.0575 12L9 10.0575L10.9425 12L12 10.9425L10.0575 9L12 7.0575L10.9425 6ZM9 1.5C4.8525 1.5 1.5 4.8525 1.5 9C1.5 13.1475 4.8525 16.5 9 16.5C13.1475 16.5 16.5 13.1475 16.5 9C16.5 4.8525 13.1475 1.5 9 1.5ZM9 15C5.6925 15 3 12.3075 3 9C3 5.6925 5.6925 3 9 3C12.3075 3 15 5.6925 15 9C15 12.3075 12.3075 15 9 15Z"
                          fill="#FC402B"
                        />
                      </svg>
                    </div>
                    <div class="cta-wrapper">
                      <div
                        class="remove-invitee"
                        v-if="!addEnabled"
                        @click="
                          invitee.isEditing
                            ? saveEmail(index)
                            : editDeliveryEmail(index)
                        "
                      >
                        {{ invitee.isEditing ? "Save" : "Edit" }}
                      </div>
                      <div
                        class="remove-invitee"
                        v-if="!addEnabled"
                        @click="deleteDeliveryEmail(invitee)"
                      >
                        Delete
                      </div>
                    </div>
                  </td>
                </tr>
              </tbody>
            </table>
          </div>
          <div
            class="no-invitees"
            v-if="
              addEnabled ? invitees?.length === 0 : existingUsers?.length === 0
            "
          >
            <AddTeamMembers />
            {{
              addEnabled ? "Add delivery emails" : "No delivery emails to show"
            }}
          </div>
        </div>
        <div class="cta-wrapper">
          <div
            class="cancel-button"
            @click="addEnabled ? back() : close()"
            style="flex-grow: 1"
          >
            <span>{{ addEnabled ? "Back" : "Close" }}</span>
          </div>
          <div
            :class="[
              'create-button',
              {
                disabled: addEnabled
                  ? invitees?.length === 0 || loading
                  : bankName === null || BranchName === null,
              },
            ]"
            @click="
              if (addEnabled) {
                if (invitees?.length > 0 && !loading) SaveDeliveryEmails();
              } else {
                if (bankName !== null && BranchName !== null) toggleAdd();
              }
            "
            :style="{ opacity: loading ? 0.5 : 1, flexGrow: 1 }"
          >
            <span>{{ addEnabled ? "Save" : "Add Delivery Email" }}</span>
          </div>
        </div>
      </div>
    </div>
  </div>
  <Toastify ref="toastContainer" />
</template>
<script>
import {
  GetBanksList,
  GetBranchList,
  AddDeliveryEmails,
  GetDeliveryEmails,
  UpdateDeliveryEmail,
  DeleteDeliveryEmail,
} from "@/api/APIs";
import Toastify from "@/components/Toastify.vue";
import AddTeamMembers from "@/assets/Animation/AddTeamMembers/AddTeamMembers.vue";

export default {
  name: "AddDeliveryEmailsModal",
  props: {},
  components: {
    Toastify,
    AddTeamMembers,
  },
  data() {
    return {
      inviteeEmail: "",
      invitees: [],
      loading: false,
      branchList: null,
      BranchName: null,
      bankName: null,
      banksList: null,
      addEnabled: false,
      existingUsers: [],
    };
  },
  watch: {
    bankName(newVal) {
      if (newVal) {
        this.branchList = null;
        this.BranchName = null;
        this.invitees = [];
        this.existingUsers = [];
        this.BranchesList();
      }
    },
    BranchName(newVal) {
      if (newVal) {
        this.DeliveryEmailsList();
      }
    },
  },
  computed: {},
  async mounted() {
    this.BanksList();
  },
  methods: {
    async BanksList() {
      try {
        const response = await GetBanksList("");
        if (response) {
          this.banksList = response?.allEntitiesResults;
        } else {
          this.$refs.toastContainer.addToast(response.message, {
            type: "error",
          });
        }
      } catch (error) {
        if (error.response && error.response.status !== 403) {
          return this.$refs.toastContainer.addToast(
            "Something went wrong. Please try again later.",
            { type: "error" }
          );
        }
      }
    },
    async BranchesList() {
      try {
        const response = await GetBranchList(this.bankName);
        if (response) {
          if (response.message !== "No branches found for given entityId !!!") {
            this.branchList = response?.allBranches;
          }
        } else {
          this.$refs.toastContainer.addToast(response.message, {
            type: "error",
          });
        }
      } catch (error) {
        if (error.response && error.response.status !== 403) {
          return this.$refs.toastContainer.addToast(
            "Something went wrong. Please try again later.",
            { type: "error" }
          );
        }
      }
    },
    async DeliveryEmailsList() {
      try {
        const response = await GetDeliveryEmails(this.BranchName);
        if (
          response.message === "SUCCESS" &&
          response.deliveryEmails?.length > 0
        ) {
          const uniqueEmails = new Set();
          this.existingUsers = response.deliveryEmails
            .filter((emailObj) => {
              if (!uniqueEmails.has(emailObj.deliveryEmail)) {
                uniqueEmails.add(emailObj.deliveryEmail);
                return true;
              }
              return false;
            })
            .map((emailObj) => ({
              id: emailObj.id,
              deliveryEmail: emailObj.deliveryEmail,
              isEditing: false,
              updatedEmail: emailObj.deliveryEmail,
            }));
        } else {
          this.existingUsers = [];
          return;
        }
      } catch (error) {
        if (error.response && error.response.status !== 403) {
          return this.$refs.toastContainer.addToast(
            "Something went wrong. Please try again later.",
            { type: "error" }
          );
        }
      }
    },
    editDeliveryEmail(index) {
      this.existingUsers[index].isEditing = true;
    },
    async saveEmail(index) {
      if (
        this.existingUsers[index].deliveryEmail ===
        this.existingUsers[index].updatedEmail
      ) {
        this.existingUsers[index].isEditing = false;
        return;
      }
      const email = this.existingUsers[index].updatedEmail;

      const emailPattern = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
      if (!email || !emailPattern.test(email)) {
        return this.$refs.toastContainer.addToast(
          "Please enter a valid email",
          { type: "error" }
        );
      }

      try {
        let payload = {
          branchUserId: this.existingUsers[index].id,
          userEmail: email,
        };
        const response = await UpdateDeliveryEmail(payload);
        if (response) {
          this.existingUsers[index].deliveryEmail = email;
          this.existingUsers[index].isEditing = false;
        }
        this.$refs.toastContainer.addToast("Email updated successfully", {
          type: "success",
        });
      } catch (error) {
        this.$refs.toastContainer.addToast(
          "Error updating email. Please try again.",
          { type: "error" }
        );
      }
    },
    async deleteDeliveryEmail(details) {
      try {
        const response = await DeleteDeliveryEmail(details);
        if (response) {
          this.DeliveryEmailsList();
        }
        this.$refs.toastContainer.addToast("Email deleted successfully", {
          type: "success",
        });
      } catch (error) {
        this.$refs.toastContainer.addToast(
          "Error updating email. Please try again.",
          { type: "error" }
        );
      }
    },
    close() {
      this.bankName = null;
      this.BranchName = null;
      this.$emit("close");
    },
    toggleAdd() {
      this.addEnabled = true;
    },
    back() {
      this.addEnabled = false;
    },
    async addInvitee() {
      if (this.branchName === null) {
        return this.$refs.toastContainer.addToast("Please select the bank", {
          type: "error",
        });
      } else if (this.BranchName === null) {
        return this.$refs.toastContainer.addToast("Please select the branch", {
          type: "error",
        });
      }
      const emailPattern = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
      if (!this.inviteeEmail || !emailPattern.test(this.inviteeEmail)) {
        return this.$refs.toastContainer.addToast(
          "Please enter a valid email",
          { type: "error" }
        );
      } else {
        this.invitees.push(this.inviteeEmail);
        this.inviteeEmail = "";
      }
    },
    removeInvitee(index) {
      this.invitees.splice(index, 1);
    },
    async SaveDeliveryEmails() {
      this.loading = true;
      try {
        let payload = {
          entityId: this.bankName,
          branchId: this.BranchName,
          userEmails: this.invitees,
        };
        const response = await AddDeliveryEmails(payload);
        if (response) {
          if (response.message === "SUCCESS") {
            this.invitees = [];
            this.DeliveryEmailsList();
            this.back();
            return this.$refs.toastContainer.addToast(
              "Delivery email list updated successfuly",
              { type: "success" }
            );
          }
        } else {
          this.$refs.toastContainer.addToast(response.message, {
            type: "error",
          });
        }
      } catch (error) {
        if (error.response && error.response.status !== 403) {
          return this.$refs.toastContainer.addToast(
            "Something went wrong. Please try again later.",
            { type: "error" }
          );
        }
      } finally {
        this.loading = false;
      }
    },
  },
};
</script>
<style scoped>
.bg-b-lue {
  background: rgba(0, 0, 0, 8%);
  display: flex;
  align-items: center;
  justify-content: center;
  backdrop-filter: blur(10px);
  z-index: 9999;
}
.frame-35628 {
  display: flex;
  flex-direction: column;
  gap: 25px;
  align-items: center;
  justify-content: flex-start;
  width: 60vw;
  position: relative;
  background: #ffffff;
  border-radius: 24px;
  min-height: 384px;
  box-shadow: 0px 24px 24px 0px rgba(17, 54, 90, 0.2);
  padding: 2rem 3rem;
}
.title {
  color: var(--Dark-Blue, #11365a);
  font-family: "Montserrat-Medium", sans-serif;
  font-size: 24px;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
  align-self: stretch;
  text-align: left;
}
.add-members-wrapper {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-start;
  align-self: stretch;
  position: relative;
  gap: 8px;
}
.invitee-email {
  padding: 5px 24px;
  align-self: stretch;
  border-radius: 16px;
  border: 1px solid #11365a;
  color: #11365a;
  font-family: "Montserrat-Medium", sans-serif;
  font-size: 16px;
  font-style: normal;
  font-weight: 500;
  line-height: 28px;
  letter-spacing: -0.16px;
  outline: none;
}
.edit-email {
  padding: 0px 24px;
  align-self: stretch;
  border-radius: 16px;
  border: 1px solid #11365a;
  color: #11365a;
  font-family: "Montserrat-Medium", sans-serif;
  font-size: 16px;
  font-style: normal;
  font-weight: 500;
  line-height: 28px;
  outline: none;
  width: 70%;
  background: #e7f0ff;
}
.invitees-wrapper,
.invitees-list {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
  align-self: stretch;
  gap: 18px;
}
.invitees-list-height {
  height: 45vh;
  overflow-y: auto;
  overflow-x: hidden;
  background: #f2f6fd;
  padding: 10px;
  border-radius: 16px;
  scrollbar-width: none;
  -ms-overflow-style: none;
}
.invitees-title {
  color: var(--Text-Mid, #3e4855);
  font-family: "Montserrat-Medium", sans-serif;
  font-size: 18px;
  font-style: normal;
  font-weight: 700;
  line-height: 150%;
  letter-spacing: 0.18px;
}
.remove-invitee {
  display: flex;
  align-items: center;
  justify-content: center;
  align-self: stretch;
  cursor: pointer;
}
.create-button {
  display: flex;
  color: white;
  font-family: "Montserrat-Regular", sans-serif;
  text-align: center;
  font-size: 16px;
  font-style: normal;
  font-weight: 600;
  line-height: 28px;
  letter-spacing: -0.16px;
  border-radius: 16px;
  padding: 6px 26px;
  justify-content: center;
  align-items: center;
  background: #11365a;
  cursor: pointer;
  align-self: stretch;
  width: 50%;
}
.cancel-button {
  width: 50%;
}
.create-button.disabled {
  cursor: not-allowed;
}
.cancel-button,
.add-button {
  color: #11365a;
  border: 2px solid #11365a;
  font-family: "Montserrat-Regular", sans-serif;
  text-align: center;
  font-size: 16px;
  font-style: normal;
  font-weight: 600;
  line-height: 28px;
  letter-spacing: -0.16px;
  border-radius: 16px;
  padding: 4px 24px;
  justify-content: center;
  align-items: center;
  cursor: pointer;
  align-self: stretch;
}
.table-wrapper {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  width: 100%;
}
#searchResultTable {
  width: 100%;
  border-collapse: collapse;
  background-color: #ffffff;
  border-radius: 16px;
  overflow: hidden;
  font-family: "Montserrat-Regular", sans-serif;
}
#searchResultTable th {
  text-align: left;
  font-family: "Montserrat-Regular", sans-serif;
  color: #11365a;
  padding: 10px 16px;
  min-width: 30px;
}
#searchResultTable td {
  text-align: left;
  padding: 10px 16px;
  color: #11365a;
  font-size: 16px;
  font-weight: 400;
  line-height: 175%;
  letter-spacing: -0.16px;
}
.no-invitees {
  align-self: stretch;
  color: #11365a5d;
  font-family: "Montserrat-Medium", sans-serif;
  font-size: 16px;
  font-style: normal;
  font-weight: 500;
  text-align: left;
  display: flex;
  gap: 10px;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  height: 100%;
}
.cta-wrapper {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  width: 100%;
  gap: 20px;
}
.input-field {
  border-radius: 16px;
  border: 1px solid #11365a;
  color: #11365a;
  font-family: "Montserrat-Medium", sans-serif;
  font-size: 16px;
  font-style: normal;
  font-weight: 500;
  line-height: 28px;
  letter-spacing: -0.16px;
  outline: none;
  width: 50%;
  height: 40px;
  padding: 5px 24px;
}

@media only screen and (max-width: 767px) {
  .frame-35628 {
    width: 80vw;
    padding: 1.5rem 1.5rem;
    gap: 20px;
  }
  .title {
    font-size: 18px;
  }
  .add-members-wrapper {
    flex-direction: column;
    gap: 10px;
  }
  .invitee-email {
    padding: 10px 20px;
  }
  .invitee-email:first-of-type {
    line-height: 20.5px;
  }
  .invitees-list-height {
    height: 30vh;
  }
  .add-button {
    padding: 6px 24px;
  }
  .create-button {
    padding: 8px 24px;
  }
  .cancel-button {
    padding: 6px 24px;
  }
  .invitees-title {
    font-size: 16px;
  }
}
@media only screen and (min-width: 768px) and (max-width: 1156px) {
  .invitee-email {
    width: 100%;
  }
  .add-button {
    width: 18%;
  }
}
@media only screen and (min-width: 1156px) {
  .invitee-email {
    width: 100%;
  }
  .add-button {
    width: 18%;
  }
}
</style>
