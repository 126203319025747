<template>
  <div class="popup bg-b-lue">
    <div class="modal">
      <div class="frame-35811">
        <div class="frame-35805">
          <div class="frame-35812">
            <i class="fa-regular fa-file-lines fa-xl" style="color: white"></i>
            <div class="create-a-manual-order">Create Single Order For - {{ selectedBank.name }}</div>
          </div>
          <div
            style="cursor: pointer; display: flex"
            v-on:click.prevent="hideManualOrderModal()"
          >
            <i class="fa-solid fa-xmark fa-xl" style="color: white"></i>
          </div>
        </div>
      </div>
      <div class="frame-35654">
        <div class="frame-35656">
          <div class="frame-35815">
            <div class="input-sections1">
              <div class="input-field-title">
                Company Name <span style="color: red">*</span>
              </div>
              <div class="frame-35739">
                <input
                  type="text"
                  :value="
                    selectedCompanyName !== '' ? selectedCompanyName : null
                  "
                  v-on:input="selectedCompanyName = $event.target.value.trim()"
                  placeholder="Enter Company Name"
                  required
                  class="input-field"
                />
              </div>
            </div>
            <div class="input-sections1">
              <div class="input-field-title">
                Country <span style="color: red">*</span>
              </div>
              <div class="frame-35740">
                <div
                  class="input-group"
                  style="
                    justify-content: center;
                    position: relative;
                    width: 100%;
                  "
                >
                  <input
                    type="text"
                    placeholder="Country/Region"
                    :value="selectedCountry.name"
                    class="input-field"
                    ref="searchInput"
                    @input="updateSelectedCountry"
                    @click="showResults"
                  />
                  <div
                    class="country-results"
                    v-if="filteredCountries.length && showResultsFlag"
                    ref="countryResults"
                  >
                    <div
                      v-for="country in filteredCountries"
                      :key="country.id"
                      @click="selectCountry(country)"
                    >
                      <template v-if="isWindows()"
                        ><img
                          :src="country.flagImage"
                          alt="Flag"
                          class="flag-image"
                        />
                        {{ country.name }}</template
                      >
                      <template v-else
                        >{{ country.flagEmoji }} {{ country.name }}</template
                      >
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div class="input-sections1">
              <div class="input-field-title">Upload Proforma Invoice</div>
              <div class="frame-357442">
                <input
                  style="padding: 10px 0"
                  id="file-upload"
                  type="file"
                  accept="image/*,.pdf,.doc,.docx,.rtf,.odt"
                  @change="handleFileUpload"
                />
              </div>
            </div>
          </div>

          <div class="frame-35815">
            <div class="input-sections1">
              <div class="input-field-title">
                Company Address <span style="color: red">*</span>
              </div>
              <textarea
                class="additional-address"
                placeholder="Enter Address (Minimum 4 words)"
                v-model.trim="additionalAddress"
                required
              ></textarea>
            </div>
            <div class="input-sections1">
              <div class="input-field-title">More Information</div>
              <textarea
                class="additional-address"
                placeholder="Provide More Information About The Company (optional)"
                v-model.trim="additionalInformation"
              ></textarea>
            </div>
            <div class="input-sections1">
              <div class="input-field-title">Remarks (If any)</div>
              <textarea
                class="additional-address"
                placeholder="Add remarks"
                v-model.trim="remarks"
              ></textarea>
            </div>
          </div>
          <div class="frame-35815" style="align-items: center">
            <div class="input-sections1">
              <div class="input-field-title">Phone</div>
              <div
                class="input-group"
                style="
                  display: flex;
                  flex-direction: row;
                  justify-content: space-between;
                  background-color: white;
                  border: 1px solid rgba(17, 54, 90, 0.5);
                  border-radius: 14px;
                  overflow: hidden;
                  width: 100%;
                "
              >
                <select
                  v-model="selectedCountryCode"
                  class="country-dropdown"
                  style="padding: 10px"
                >
                  <option value="" disabled>Select a country</option>
                  <option
                    v-for="country in countries"
                    :value="country"
                    :key="country.code"
                    class="country-option"
                  >
                    {{ country.name }} - (+{{
                      getFirstDialCode(country.dialCode)
                    }})
                  </option>
                </select>
                <input
                  type="tel"
                  v-model.trim="phone"
                  placeholder="Phone"
                  style="
                    width: 65%;
                    border: none;
                    outline: none;
                    padding: 10px 24px;
                  "
                  inputmode="tel"
                  autocomplete="tel"
                  required
                  pattern="[0-9()+\()-]*"
                />
              </div>
            </div>
            <div class="input-sections1">
              <div class="input-field-title">Landline</div>
              <div
                class="input-group"
                style="
                  display: flex;
                  flex-direction: row;
                  justify-content: space-between;
                  background-color: white;
                  border: 1px solid rgba(17, 54, 90, 0.5);
                  border-radius: 14px;
                  overflow: hidden;
                  width: 100%;
                "
              >
                <input
                  type="tel"
                  v-model.trim="areaCode"
                  placeholder="Area code"
                  style="
                    width: 30%;
                    outline: none;
                    border-left: none;
                    border-top: none;
                    border-bottom: none;
                    border-right: 1px solid rgba(87, 87, 87, 0.5);
                    border-top-right-radius: 0;
                    border-bottom-right-radius: 0;
                    padding: 10px;
                    padding-left: 25px;
                  "
                  inputmode="tel"
                  autocomplete="tel"
                  required
                  maxlength="8"
                  pattern="[0-9()+\()-]*"
                />
                <input
                  type="tel"
                  v-model.trim="landline"
                  placeholder="Enter Landline"
                  style="
                    width: 70%;
                    border: none;
                    outline: none;
                    padding: 10px 24px;
                  "
                  inputmode="tel"
                  autocomplete="tel"
                  required
                  maxlength="25"
                  pattern="[0-9()+\()-]*"
                />
              </div>
            </div>
            <div class="input-sections1">
              <div class="input-field-title">Reference Number</div>
              <input
                type="text"
                placeholder="Reference Number"
                class="input-field"
                v-model.trim="referenceNumber"
              />
            </div>
          </div>
          <div class="frame-35815">
            <div class="input-sections1">
              <div
                class="input-field-title"
                style="display: flex; flex-direction: row; gap: 10px"
              >
                <span>Client Name</span>
              </div>
              <input
                type="text"
                placeholder="Enter Client Name"
                class="input-field"
                v-model.trim="clientName"
              />
            </div>
            <div class="input-sections1">
              <div class="input-field-title">Client Country</div>
              <div class="frame-35740">
                <div
                  class="input-group"
                  style="
                    justify-content: center;
                    position: relative;
                    width: 100%;
                  "
                >
                  <input
                    type="text"
                    placeholder="Country/Region"
                    :value="selectedCountry1.name"
                    class="input-field"
                    ref="searchInput1"
                    @input="updateSelectedCountry1"
                    @click="showResults1"
                  />
                  <div
                    class="country-results"
                    v-if="filteredCountries1.length && showResultsFlag1"
                    ref="countryResults1"
                  >
                    <div
                      v-for="country in filteredCountries1"
                      :key="country.id"
                      @click="selectCountry1(country)"
                    >
                      <template v-if="isWindows()"
                        ><img
                          :src="country.flagImage"
                          alt="Flag"
                          class="flag-image"
                        />
                        {{ country.name }}</template
                      >
                      <template v-else
                        >{{ country.flagEmoji }} {{ country.name }}</template
                      >
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div class="input-sections1">
              <div class="input-field-title">Original Order Date</div>
              <input
                type="date"
                v-model="originalOrderDate"
                class="input-field"
                style="padding: 10px; height: 37.5px !important"
                :max="maxDate"
              />
            </div>
          </div>
          <div class="frame-35815">
            <div class="input-sections1">
              <div class="input-field-title">
                Branch Name <span style="color: red">*</span>
              </div>
              <select
                v-model="BranchName"
                class="input-field dropdown-1"
                :style="{
                  color: BranchName === null ? '#757575' : '#000001',
                }"
              >
                <option value="null" disabled>
                  {{
                    branchList === null
                      ? "No Branches to show"
                      : "Select Branch"
                  }}
                </option>
                <option
                  v-for="(branchDetails, index) in branchList"
                  :value="branchDetails"
                  :key="index"
                  class="country-option"
                >
                  {{ branchDetails?.branchName }}
                </option>
              </select>
            </div>
            <div class="input-sections1">
              <div class="input-field-title">
                Deliver To <span style="color: red">*</span>
              </div>
              <select
                v-model="selectedEmails"
                class="input-field dropdown-1"
                :style="{
                  color: selectedEmails === null ? '#757575' : '#000001',
                }"
                :disabled="emailList?.length === 0"
              >
                <option value="null" disabled>Select Delivery Email</option>
                <option
                  v-for="(emails, index) in emailList"
                  :value="emails.email"
                  :key="index"
                  class="country-option"
                >
                  {{ emails?.email }}
                </option>
              </select>
            </div>
            <div class="input-sections1">
              <div class="input-field-title">Urgent</div>
              <div class="urgent-repeat">
                <div>
                  <input type="checkbox" v-model="isUrgent" />
                  <label for="true">Urgent</label>
                </div>
              </div>
            </div>
          </div>
          <div class="frame-35815" style="align-items: center">
            <div class="input-sections1" style="width: 100%">
              <div class="input-field-title">CC</div>
              <div class="frame-35739">
                <Multiselect
                  v-model="selectedEmailsCC"
                  :options="emailList"
                  placeholder="Select CC Emails*"
                  multiple
                  track-by="email"
                  label="email"
                  :disabled="emailList?.length === 0"
                >
                </Multiselect>
              </div>
            </div>
          </div>
        </div>
        <div class="frame-35647">
          <div
            class="order-btn"
            :style="[
              { backgroundColor: createButtonColor },
              { color: createButtonTextColor },
              {
                order: 2,
              },
            ]"
            :disabled="isOrderButtonDisabled"
            v-on:click.prevent="disabled === false ? handleButtonClick() : none"
          >
            Create
          </div>
          <div
            class="cancel-btn"
            style="opacity: 1; background-color: white; order: 1"
            v-on:click.prevent="hideManualOrderModal()"
          >
            Cancel
          </div>
        </div>
      </div>
    </div>
  </div>
  <Toastify ref="toastContainer" />
</template>
<script>
import {
  AllCountries,
  FileUpload,
  AdminSingleOrder,
  GetBranchList,
  GetDeliveryEmails,
} from "@/api/APIs";
// import VueCookies from "vue-cookies";
import Toastify from "@/components/Toastify.vue";
import { mapActions } from "vuex";
import Multiselect from "vue-multiselect";
import "vue-multiselect/dist/vue-multiselect.min.css";
// import { Dropdown } from "floating-vue";

export default {
  name: "RmManualOrder",
  components: {
    Toastify,
    Multiselect,
    // Dropdown,
  },
  props: {
    hideManualOrderModal: {
      type: Function,
      required: true,
    },
    selectedBank: {
      type: Object,
      required: true,
    },
    clickedSearchResult: {
      type: Object,
      required: true,
    },
    productVariant: {
      type: Object,
      required: true,
    },
    selectedCompany: {
      type: String,
      required: true,
    },
    country: {
      type: Object,
      required: true,
    },
  },
  data() {
    const hasClickedSearchResultAddress =
      this.clickedSearchResult?.additional_info?.business_address ||
      this.clickedSearchResult?.additional_info?.company_address;
    return {
      uploadedFile: null,
      uploadedFileName: "",
      selectedCompanyName: this.selectedCompany ? this.selectedCompany : "",
      phone: "",
      landline: "",
      areaCode: "",
      additionalAddress:
        this.clickedSearchResult?.additional_info?.business_address ||
        this.clickedSearchResult?.additional_info?.company_address ||
        this.productVariant?.availableAddresses?.[0]?.registered_address?.full_address ||
        "",
      additionalInformation: hasClickedSearchResultAddress
        ? this.productVariant?.availableAddresses?.[0]?.registered_address
            ?.full_address || ""
        : "",
      referenceNumber: "",
      fileKey: "",
      selectedCountry: this.country ? this.country : {},
      selectedCountryCode: this.country ? this.country : {},
      selectedCountry1: {},
      countries: [],
      filteredCountries1: [],
      filteredCountries: [],
      showResultsFlag: false,
      showResultsFlag1: false,
      BranchName: null,
      remarks: "",
      branchList: null,
      isUrgent: false,
      windowWidth: window.innerWidth,
      showOrderConfirmationModal: false,
      disabled: false,
      selectedEmails: null,
      selectedEmailsCC: [],
      emailList: [],
      originalOrderDate: this.getCurrentDate(),
      maxDate: this.getCurrentDate(),
      clientName: "",
    };
  },
  watch: {
    BranchName(newVal) {
      if (newVal) {
        this.DeliveryEmailsList();
      }
    },
  },
  mounted() {
    window.addEventListener("resize", this.handleResize);
    this.fetchCountryList();
    this.BranchesList();
  },
  beforeUnmount() {
    window.removeEventListener("resize", this.handleResize);
  },
  computed: {
    currentDate() {
      const today = new Date();
      return today.toISOString().split("T")[0];
    },
    showToast() {
      return this.$refs.toastContainer.addToast(
        "Please fill all the mandatory fields to place an order.",
        { type: "error" }
      );
    },
    isOrderButtonDisabled() {
      if (
        this.additionalAddress !== "" &&
        this.selectedCompanyName !== "" &&
        this.selectedCountry.name !== undefined &&
        this.BranchName !== null
      ) {
        return true;
      }

      return false;
    },
    createButtonColor() {
      return this.isOrderButtonDisabled ? "#26BBC2" : "#a2dfe3";
    },
    createButtonTextColor() {
      return this.isOrderButtonDisabled ? "#04162D" : "#969da7";
    },
    cancelButtonColor() {
      return this.isOrderButtonDisabled ? "1" : "0.5";
    },
    cancelButtonTextColor() {
      return this.isOrderButtonDisabled ? "#04162D" : "#11365a";
    },
  },
  methods: {
    ...mapActions(["updateCartCount"]),
    getCurrentDate() {
      const today = new Date();
      const year = today.getFullYear();
      const month = String(today.getMonth() + 1).padStart(2, "0");
      const day = String(today.getDate()).padStart(2, "0");
      return `${year}-${month}-${day}`;
    },
    async DeliveryEmailsList() {
      try {
        this.emailList = [];
        const response = await GetDeliveryEmails(this.BranchName.id);
        if (
          response.message === "SUCCESS" &&
          response.deliveryEmails?.length > 0
        ) {
          this.emailList.push(
            ...response.deliveryEmails.map((emailObj) => ({
              email: emailObj.deliveryEmail,
            }))
          );
        } else {
          this.emailList = [];
          return;
        }
      } catch (error) {
        if (error.response && error.response.status !== 403) {
          return this.$refs.toastContainer.addToast(
            "Something went wrong. Please try again later.",
            { type: "error" }
          );
        }
      }
    },
    async BranchesList() {
      try {
        const response = await GetBranchList(this.selectedBank?.id);
        if (response) {
          if (response.message !== "No branches found for given entityId !!!") {
            this.branchList = response?.allBranches;
          }
        } else {
          this.$refs.toastContainer.addToast(response.message, {
            type: "error",
          });
        }
      } catch (error) {
        if (error.response && error.response.status !== 403) {
          return this.$refs.toastContainer.addToast(
            "Something went wrong. Please try again later.",
            { type: "error" }
          );
        }
      }
    },
    updateSelectedCountry(event) {
      this.selectedCountry = {
        ...this.selectedCountry,
        name: event.target.value,
      };
      this.filterCountries();
    },
    updateSelectedCountry1(event) {
      this.selectedCountry1 = {
        ...this.selectedCountry1,
        name: event.target.value,
      };
      this.filterCountries1();
    },
    handleButtonClick() {
      if (!this.isOrderButtonDisabled) {
        this.showToast;
      } else {
        this.placeOrder();
      }
    },
    handleResize() {
      this.windowWidth = window.innerWidth;
    },
    isWindows() {
      return /Windows/.test(window.navigator.userAgent);
    },
    async handleFileUpload(event) {
      const file = event.target.files[0];

      if (!file) {
        return;
      }

      const allowedTypes = [
        "application/pdf",
        "application/msword",
        "application/rtf",
        "application/vnd.oasis.opendocument.text",
      ];

      if (
        !allowedTypes.includes(file.type) &&
        !file.type.startsWith("image/")
      ) {
        event.target.value = "";
        return this.$refs.toastContainer.addToast(
          "Invalid file type. Please upload a valid file.",
          { type: "error" }
        );
      }

      this.uploadedFile = file;
      this.uploadedFileName = file.name;

      const formData = new FormData();
      formData.append("file", file);

      try {
        const response = await FileUpload(formData);
        if (
          response &&
          response.message === "SUCCESS" &&
          response.fileResults.length > 0
        ) {
          const fileResult = response.fileResults[0];
          this.fileKey = fileResult.fileKey;
        }
      } catch (error) {
        this.loginError = "Error occurred during upload.";
        console.error(error);
      }
    },
    getFirstDialCode(dialCodeString) {
      return dialCodeString.split(",")[0];
    },
    formatPhoneNumber() {
      this.phone = this.phone.replace(/\D/g, "");
    },
    async fetchCountryList() {
      try {
        const response = await AllCountries(false);
        this.countries = await response.allCountries;
      } catch (error) {
        this.loginError = "Error occurred during login.";
        console.error(error);
      }
    },
    filterCountries() {
      this.showResultsFlag = true;
      this.filteredCountries = this.countries.filter((country) =>
        country.name.toLowerCase().includes(this.selectedCountry?.name)
      );
    },
    filterCountries1() {
      this.showResultsFlag1 = true;
      this.filteredCountries1 = this.countries.filter((country) =>
        country.name.toLowerCase().includes(this.selectedCountry1?.name)
      );
    },
    selectCountry(country) {
      this.selectedCountry = country;
      this.filteredCountries = [];
    },
    selectCountry1(country) {
      this.selectedCountry1 = country;
      this.filteredCountries1 = [];
    },
    showResults() {
      this.showResultsFlag = true;
      this.filteredCountries = this.countries;
      document.addEventListener("click", this.closeResultsOnClickOutside);
    },
    showResults1() {
      this.showResultsFlag1 = true;
      this.filteredCountries1 = this.countries;
      document.addEventListener("click", this.closeResultsOnClickOutside1);
    },
    closeResultsOnClickOutside(event) {
      const searchInput = this.$refs.searchInput;
      const countryResults = this.$refs.countryResults;
      if (
        searchInput &&
        !searchInput.contains(event.target) &&
        countryResults &&
        !countryResults.contains(event.target)
      ) {
        this.showResultsFlag = false;
        document.removeEventListener("click", this.closeResultsOnClickOutside);
      }
    },
    closeResultsOnClickOutside1(event) {
      const searchInput = this.$refs.searchInput1;
      const countryResults = this.$refs.countryResults1;
      if (
        searchInput &&
        !searchInput.contains(event.target) &&
        countryResults &&
        !countryResults.contains(event.target)
      ) {
        this.showResultsFlag1 = false;
        document.removeEventListener("click", this.closeResultsOnClickOutside1);
      }
    },
    isCountrySelected() {
      return this.countries.some(
        (country) => country.name === this.selectedCountry.name
      );
    },
    async placeOrder() {
      this.disabled = true;
      if (!this.isCountrySelected()) {
        this.disabled = false;
        return this.$refs.toastContainer.addToast(
          "Please select a valid country from the dropdown.",
          { type: "error" }
        );
      }

      if (
        !this.selectedCompanyName ||
        this.selectedCompanyName.trim().length === 0
      ) {
        this.disabled = false;
        return this.$refs.toastContainer.addToast(
          "Company name cannot be empty or contain only blank spaces.",
          { type: "error" }
        );
      }

      if (this.BranchName === null) {
        this.disabled = false;
        return this.$refs.toastContainer.addToast(
          "Please select a branch name",
          { type: "error" }
        );
      }

      if (this.selectedEmails === null) {
        this.disabled = false;
        return this.$refs.toastContainer.addToast(
          "Please select the delivery email",
          { type: "error" }
        );
      }

      const invalidAddressTexts = ["na", "n/a", "not applicable", "-"];
      const isInvalidAddress =
        this.additionalAddress &&
        invalidAddressTexts.includes(
          this.additionalAddress.trim().toLowerCase()
        );
      const addressWordCount =
        this.additionalAddress &&
        this.additionalAddress.trim().split(/\s+/).length;

      if (
        isInvalidAddress ||
        addressWordCount < 4 ||
        this.additionalAddress.trim().length === 0
      ) {
        this.disabled = false;
        return this.$refs.toastContainer.addToast(
          "Please enter a valid additional address with at least 4 words.",
          { type: "error" }
        );
      }

      const firstDialCode =
        this.phone !== "" &&
        this.getFirstDialCode(this.selectedCountryCode.dialCode);

      const phoneNumberLengths =
        this.selectedCountryCode && this.selectedCountryCode.phoneLength
          ? this.selectedCountryCode.phoneLength
              .split(",")
              .map((length) => parseInt(length.trim()))
          : [];
      if (
        this.phone !== "" &&
        this.phone.trim().length === 0 &&
        !phoneNumberLengths.includes(this.phone.length)
      ) {
        this.isLoading = false;
        this.disabled = false;
        return this.$refs.toastContainer.addToast(
          `Phone number length should be ${this.selectedCountryCode?.phoneLength} digits`,
          { type: "error" }
        );
      }
      if (this.landline !== "" && this.landline.trim().length === 0) {
        this.disabled = false;
        return this.$refs.toastContainer.addToast(
          `Landline number cannot contain only blank spaces.`,
          { type: "error" }
        );
      }
      if (this.landline !== "" && this.areaCode === "") {
        this.isLoading = false;
        this.disabled = false;
        return this.$refs.toastContainer.addToast(
          "Please enter the area code for the provided landline number.",
          { type: "error" }
        );
      }

      if (this.areaCode !== "" && this.areaCode.trim().length === 0) {
        this.disabled = false;
        return this.$refs.toastContainer.addToast(
          `Area code cannot contain only blank spaces.`,
          { type: "error" }
        );
      }

      if (this.referenceNumber && this.referenceNumber.trim().length > 0) {
        this.includeReferenceNumber = true;
      }

      const phoneNo =
        this.phone !== "" ? `+${firstDialCode}-${this.phone}` : "";
      const fullLandline =
        this.landline !== "" ? `${this.areaCode}-${this.landline}` : "";

      try {
        const payload = {
          subjectName: this.selectedCompanyName,
          subjectAddress: this.additionalAddress,
          subjectCountry: this.selectedCountry?.id,
          orderContactNumber: phoneNo,
          orderLandlineNumber: fullLandline,
          moreInformation: this.additionalInformation,
          clientName: this.clientName,
          clientCountry: this.selectedCountry1.id,
          referenceNumber: this.referenceNumber,
          branchName: this.BranchName.branchName,
          piFileKey: this.fileKey,
          orderEntity: this.selectedBank.id,
          isUrgent: this.isUrgent,
          remarks: this.remarks,
          orderEmail: this.selectedEmails,
          orderEmailCC: this.selectedEmailsCC.map((e) => e.email).join(", "),
          originalOrderDate: this.originalOrderDate,
        };
        const response = await AdminSingleOrder(payload);
        if (response && response.message == "SUCCESS") {
          this.disabled = false;
          this.hideManualOrderModal();
          this.$refs.toastContainer.addToast("Order created successfully", {
            type: "success",
          });
        } else {
          this.disabled = false;
          return this.$refs.toastContainer.addToast(
            "Something went wrong. Please try again later.",
            { type: "error" }
          );
        }
      } catch (error) {
        this.disabled = false;
        if (error.response && error.response.status !== 403) {
          return this.$refs.toastContainer.addToast(
            "Something went wrong. Please try again later.",
            { type: "error" }
          );
        }
      }
      this.$root.hideLoader();
    },
  },
};
</script>
<style scoped>
.modal,
.modal * {
  box-sizing: border-box;
}
.bg-b-lue {
  background: rgba(0, 0, 0, 8%);
  display: flex;
  align-items: center;
  justify-content: center;
  backdrop-filter: blur(10px);
  z-index: 9999;
}
.disabled {
  background-color: gray;
  cursor: not-allowed;
}
.flag-image {
  width: 20px;
  height: 15px;
}
.tooltip-content-1 {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  padding: 10px;
  height: 8px;
  /* width: 180px; */
  background-color: #585858;
}
.tooltip-message {
  color: white;
  text-align: center;
  font-family: "Montserrat-Medium", sans-serif;
  font-size: 12px;
  font-weight: 500;
  line-height: 24px;
}
.dropdown-1 {
  padding: 10px;
  height: 37.5px !important;
}
.modal {
  background: #e7ebf0;
  border-radius: 24px;
  display: flex;
  flex-direction: column;
  gap: 0px;
  align-items: center;
  justify-content: flex-start;
  position: relative;
  box-shadow: 0px 24px 24px 0px rgba(17, 54, 90, 0.2);
  overflow: hidden;
  width: 90%;
  max-height: 95%;
}
.frame-35811 {
  background: #11365a;
  padding: 15px 40px;
  display: flex;
  flex-direction: column;
  gap: 10px;
  align-items: center;
  justify-content: flex-start;
  align-self: stretch;
  flex-shrink: 0;
  position: relative;
  overflow: hidden;
}
.frame-35805 {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  align-self: stretch;
  flex-shrink: 0;
  position: relative;
}
.frame-35812 {
  display: flex;
  flex-direction: row;
  gap: 24px;
  align-items: center;
  justify-content: flex-start;
  flex-shrink: 0;
  position: relative;
}
.input-sections1 {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: center;
  gap: 5px;
  width: 32.5%;
}
.input-field-title {
  font-size: 13px;
  font-weight: 600;
  font-family: "Montserrat-Medium", sans-serif;
  text-align: left;
  width: 100%;
}
.create-a-manual-order {
  color: white;
  text-align: left;
  font-family: "Montserrat-Bold", sans-serif;
  font-size: 18px;
  font-weight: 600;
  position: relative;
}
.frame-35654 {
  padding: 10px 40px 10px 40px;
  display: flex;
  flex-direction: column;
  gap: 20px;
  align-items: center;
  justify-content: flex-start;
  align-self: stretch;
  flex-shrink: 0;
  position: relative;
}
.frame-35656 {
  display: flex;
  flex-direction: column;
  gap: 15px;
  align-items: flex-start;
  justify-content: flex-start;
  align-self: stretch;
  flex: 1;
  position: relative;
}
.frame-35815 {
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  justify-content: space-between;
  align-self: stretch;
  flex-shrink: 0;
  position: relative;
}
.frame-35739 {
  display: flex;
  flex-direction: column;
  gap: 8px;
  align-items: flex-start;
  justify-content: center;
  flex: 1;
  position: relative;
  width: 100%;
}
.frame-35559 {
  background: #ffffff;
  border-radius: 13px;
  border-style: solid;
  border-color: rgba(17, 54, 90, 0.5);
  border-width: 1px;
  display: flex;
  flex-direction: row;
  gap: 10px;
  align-items: center;
  justify-content: flex-start;
  align-self: stretch;
  flex-shrink: 0;
  min-width: 150px;
  position: relative;
}
.enter-company-name {
  color: #3e4855;
  text-align: center;
  font-family: "Montserrat-Regular", sans-serif;
  font-size: 14px;
  font-weight: 400;
  position: relative;
}
.frame-35740 {
  display: flex;
  flex-direction: column;
  gap: 8px;
  align-items: flex-start;
  justify-content: center;
  flex: 1;
  width: 100%;
  position: relative;
}
.frame-355592 {
  background: #ffffff;
  border-radius: 13px;
  border-style: solid;
  border-color: rgba(17, 54, 90, 0.5);
  border-width: 1px;
  padding: 10px 24px 10px 24px;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  align-self: stretch;
  flex-shrink: 0;
  height: 40px;
  min-width: 150px;
  position: relative;
}
.frame-35818 {
  display: flex;
  flex-direction: row;
  gap: 10px;
  align-items: center;
  justify-content: flex-start;
  flex-shrink: 0;
  position: relative;
}
.sri-lanka-lk {
  flex-shrink: 0;
  width: 14px;
  height: 11px;
  position: relative;
  overflow: hidden;
}
.vector {
  width: 100%;
  height: 100%;
  position: absolute;
  right: 0%;
  left: 0%;
  bottom: 0%;
  top: 0%;
  overflow: visible;
}
.sri-lanka {
  color: #3e4855;
  text-align: center;
  font-family: "Montserrat-Regular", sans-serif;
  font-size: 16px;
  font-weight: 400;
  position: relative;
}
.vector-1 {
  flex-shrink: 0;
  width: 10px;
  height: 5px;
  position: relative;
  overflow: visible;
}
.frame-35816 {
  display: flex;
  flex-direction: row;
  gap: 40px;
  align-items: flex-start;
  justify-content: flex-start;
  align-self: stretch;
  flex-shrink: 0;
  position: relative;
}
.frame-35741 {
  display: flex;
  flex-direction: column;
  gap: 8px;
  align-items: flex-start;
  justify-content: center;
  flex: 1;
  position: relative;
}
.frame-355593 {
  background: #ffffff;
  border-radius: 13px;
  border-style: solid;
  border-color: rgba(17, 54, 90, 0.5);
  border-width: 1px;
  padding: 17px 24px 17px 24px;
  display: flex;
  flex-direction: row;
  gap: 10px;
  align-items: flex-start;
  justify-content: flex-start;
  align-self: stretch;
  flex-shrink: 0;
  height: 80px;
  min-width: 150px;
  position: relative;
}
.enter-address {
  color: #3e4855;
  text-align: center;
  font-family: "Montserrat-Regular", sans-serif;
  font-size: 14px;
  font-weight: 400;
  position: relative;
}
.frame-35745 {
  display: flex;
  flex-direction: column;
  gap: 8px;
  align-items: flex-start;
  justify-content: center;
  flex: 1;
  position: relative;
}
.provide-more-information-about-the-company {
  color: #3e4855;
  text-align: center;
  font-family: "Montserrat-Regular", sans-serif;
  font-size: 14px;
  font-weight: 400;
  position: relative;
}
.frame-35744 {
  display: flex;
  flex-direction: row;
  gap: 40px;
  align-items: flex-start;
  justify-content: flex-start;
  align-self: stretch;
  flex-shrink: 0;
  position: relative;
}
.frame-35819 {
  display: flex;
  flex-direction: column;
  gap: 8px;
  align-items: flex-start;
  justify-content: center;
  flex-shrink: 0;
  position: relative;
}
.frame-355594 {
  background: #ffffff;
  border-radius: 13px;
  border-style: solid;
  border-color: rgba(17, 54, 90, 0.5);
  border-width: 1px;
  padding: 10px 24px 10px 24px;
  display: flex;
  flex-direction: row;
  gap: 10px;
  align-items: center;
  justify-content: flex-start;
  flex-shrink: 0;
  width: 565px;
  height: 40px;
  min-width: 150px;
  position: relative;
}
.enter-customer-name {
  color: #3e4855;
  text-align: center;
  font-family: "Montserrat-Regular", sans-serif;
  font-size: 14px;
  font-weight: 400;
  position: relative;
}
.frame-35820 {
  display: flex;
  flex-direction: column;
  gap: 8px;
  align-items: flex-start;
  justify-content: center;
  flex-shrink: 0;
  position: relative;
}
.frame-35560 {
  background: #ffffff;
  border-radius: 13px;
  border-style: solid;
  border-color: rgba(17, 54, 90, 0.5);
  border-width: 1px;
  padding: 10px 24px 10px 24px;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  flex-shrink: 0;
  width: 565px;
  height: 40px;
  min-width: 150px;
  position: relative;
}
.select-customer-type {
  color: #3e4855;
  text-align: center;
  font-family: "Montserrat-Regular", sans-serif;
  font-size: 14px;
  font-weight: 400;
  position: relative;
}
.frame-35817 {
  display: flex;
  flex-direction: row;
  gap: 40px;
  align-items: flex-start;
  justify-content: flex-start;
  align-self: stretch;
  flex-shrink: 0;
  position: relative;
}
.frame-35807 {
  display: flex;
  flex-direction: column;
  gap: 8px;
  align-items: flex-start;
  justify-content: flex-start;
  flex: 1;
  position: relative;
}
.reference-number-optional {
  color: #3e4855;
  text-align: center;
  font-family: "Montserrat-Regular", sans-serif;
  font-size: 14px;
  font-weight: 400;
  opacity: 0.5;
  position: relative;
}
.frame-35627 {
  display: flex;
  flex-direction: row;
  gap: 16px;
  align-items: center;
  justify-content: flex-start;
  flex-shrink: 0;
  position: relative;
}
.rectangle-10 {
  background: #ffffff;
  border-radius: 4px;
  border-style: solid;
  border-color: #18c3df !important;
  border-width: 2px;
  flex-shrink: 0;
  width: 18px;
  height: 18px;
  position: relative;
  cursor: pointer;
  appearance: none;
}
.rectangle-10:checked {
  background-color: #18c3df !important;
}
.rectangle-10:checked:after {
  content: "\2713";
  color: white !important;
  font-size: 20px;
  position: absolute;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
}
.include-in-the-final-report {
  color: var(--text-dark, #04162d);
  text-align: center;
  font-family: "Montserrat-Regular", sans-serif;
  font-size: 16px;
  line-height: 150%;
  font-weight: 600;
  position: relative;
}
.frame-35743 {
  display: flex;
  flex-direction: column;
  gap: 8px;
  align-items: flex-start;
  justify-content: flex-start;
  align-self: stretch;
  flex: 1;
  position: relative;
}
.upload-proforma-invoice-optional {
  color: var(--text-dark, #04162d);
  text-align: left;
  font-family: "Montserrat-SemiBold", sans-serif;
  font-size: 16px;
  line-height: 150%;
  font-weight: 600;
  position: relative;
}
.frame-357442 {
  display: flex;
  flex-direction: row;
  gap: 8px;
  align-items: center;
  justify-content: flex-start;
  flex-shrink: 0;
  position: relative;
}
.frame-355595 {
  background: #ffffff;
  border-radius: 13px;
  border-style: solid;
  border-color: rgba(17, 54, 90, 0.5);
  border-width: 1px;
  padding: 10px 24px 10px 24px;
  display: flex;
  flex-direction: row;
  gap: 10px;
  align-items: center;
  justify-content: flex-start;
  flex-shrink: 0;
  min-width: 150px;
  position: relative;
}
.choose-file {
  color: #3e4855;
  text-align: center;
  font-family: "Montserrat-Regular", sans-serif;
  font-size: 14px;
  font-weight: 400;
  position: relative;
}
.no-file-chosen {
  color: #3e4855;
  text-align: center;
  font-family: "Montserrat-Regular", sans-serif;
  font-size: 14px;
  font-weight: 400;
  position: relative;
}
.frame-35814 {
  display: flex;
  flex-direction: row;
  gap: 40px;
  align-items: flex-start;
  justify-content: flex-start;
  align-self: stretch;
  flex-shrink: 0;
  position: relative;
}
.frame-35806 {
  display: flex;
  flex-direction: column;
  gap: 10px;
  align-items: flex-start;
  justify-content: flex-start;
  flex: 1;
  position: relative;
}
.select-your-report {
  color: var(--text-dark, #04162d);
  text-align: center;
  font-family: "Montserrat-SemiBold", sans-serif;
  font-size: 16px;
  line-height: 150%;
  font-weight: 600;
  position: relative;
}
.frame-35664 {
  display: flex;
  flex-direction: row;
  gap: 16px;
  align-items: center;
  justify-content: flex-start;
  flex-shrink: 0;
  position: relative;
}
.frame-35630 {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-start;
  align-self: stretch;
  flex-shrink: 0;
  position: relative;
  gap: 40px;
}
.fresh-report {
  color: var(--text-dark, #04162d);
  text-align: center;
  font-family: "Montserrat-Regular", sans-serif;
  font-size: 16px;
  line-height: 150%;
  font-weight: 400;
  position: relative;
}
.in-8-hrs {
  color: var(--text-dark, #04162d);
  text-align: center;
  font-family: "Montserrat-Italic", sans-serif;
  font-size: 14px;
  line-height: 150%;
  font-weight: 400;
  font-style: true;
  position: relative;
}
.frame-35753 {
  display: flex;
  flex-direction: column;
  gap: 10px;
  align-items: flex-start;
  justify-content: flex-start;
  position: relative;
  width: 50%;
}
.frame-35631 {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  align-self: stretch;
  flex-shrink: 0;
  position: relative;
}
.frame-356642 {
  display: flex;
  flex-direction: row;
  gap: 16px;
  align-items: center;
  justify-content: flex-start;
  flex: 1;
  position: relative;
}
.delivery-to-samplemail-gmail-com-span {
  color: #3e4855;
  font-family: "Montserrat-Regular", sans-serif;
  font-size: 14px;
  line-height: 150%;
  font-weight: 400;
}
.delivery-to-samplemail-gmail-com-span2 {
  color: #3e4855;
  font-family: "Montserrat-Regular", sans-serif;
  font-size: 14px;
  line-height: 150%;
  font-weight: 400;
  text-decoration: underline;
}
.frame-35647 {
  display: flex;
  flex-direction: row;
  gap: 24px;
  align-items: center;
  justify-content: flex-start;
  align-self: stretch;
  flex-shrink: 0;
  position: relative;
  margin-bottom: 20px;
}
.order-btn {
  padding: 10px 24px 10px 24px !important;
  align-self: stretch !important;
  width: 100%;
  font-weight: 600;
  border-radius: 13px;
  font-family: "Montserrat-Regular", sans-serif;
  cursor: pointer;
  color: white;
}
.cancel-btn {
  padding: 10px 24px 10px 24px !important;
  align-self: stretch !important;
  background-color: white;
  width: 100%;
  font-weight: 600;
  border-radius: 13px;
  font-family: "Montserrat-Regular", sans-serif;
  cursor: pointer;
}
.input-field,
input[type="date"] {
  border-radius: 13px;
  height: auto !important;
  box-shadow: none;
  width: 100%;
  outline: none;
  border: none;
  padding: 10px 25px;
  border: 1px solid rgba(17, 54, 90, 0.5);
  margin: 0;
}
.country-results {
  width: 100%;
  max-height: 200px;
  display: flex;
  flex-direction: column;
  align-items: center;
  position: absolute;
  background-color: white;
  border-radius: 15px;
  overflow-y: auto;
  top: 110%;
  z-index: 9999;
  box-shadow: 0px 16px 16px 0px rgba(20, 45, 77, 0.1);
  scrollbar-width: none;
  -ms-overflow-style: none;
}
.country-results div {
  width: 100%;
  display: flex;
  position: relative;
  align-items: flex-start;
  padding: 5px 0 5px 20px;
  text-align: left;
  font-size: 14px;
}
.country-results div:hover {
  background-color: #dee9f2;
}
.user-details-wrapper {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
}
.country-dropdown {
  width: 35% !important;
  outline: none;
  border: none !important;
}
.additional-address {
  width: 100%;
  font-family: "Montserrat-Regular", sans-serif;
  box-shadow: none;
  border-radius: 13px;
  outline: none;
  border: 1px solid rgba(17, 54, 90, 0.5);
  margin: 0;
}
textarea {
  height: 65px;
}
.order-total {
  font-size: 16px;
  font-weight: 600;
  font-family: "Montserrat-Regular", sans-serif;
  text-align: left;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  width: 100%;
}
.USD-wrapper {
  display: flex;
  width: 100%;
  flex-direction: column;
  align-items: center;
  justify-content: flex-start;
  gap: 10px;
  margin-top: 20px;
}
.USD {
  width: 100%;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  font-size: 18px;
  font-weight: 500;
  font-family: "Montserrat-Regular", sans-serif;
}
.currency-title {
  font-size: 14px;
  font-weight: 500;
  font-family: "Montserrat-Regular", sans-serif;
}
.all-currency-wrapper {
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
  align-items: center;
  text-align: right;
  gap: 15px;
}
.currency-dropdown {
  width: 22% !important;
  outline: none;
  border: 1px solid rgba(87, 87, 87, 0.5);
  border-radius: 13px;
  padding: 10px;
}
.remember-me {
  font-family: "Montserrat-Medium", sans-serif;
  font-size: 14px;
}
.notes {
  padding: 10px 20px;
  border-radius: 16px;
  background: white;
  box-shadow: -4px -4px 8px 0px #fff, 4px 4px 8px 0px rgba(0, 0, 0, 0.25);
  width: 100%;
}
.note-title {
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  justify-content: flex-start;
  font-family: "Montserrat-Light", sans-serif;
  text-align: left;
  gap: 7px;
  font-weight: 500;
  font-size: small;
}
.note-text {
  text-align: left;
  padding-left: 5px;
  font-family: "Montserrat-Regular", sans-serif;
  font-size: 13px;
  line-height: 150%;
}
.urgent-repeat {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-start;
  gap: 40px;
  height: 37.5px;
}
.urgent-repeat div {
  display: flex;
  gap: 10px;
}

@media only screen and (max-width: 767px) {
  .bg-b-lue {
    align-items: flex-end;
  }
  .notes {
    padding: 8px;
    border-radius: 8px;
  }
  .modal {
    border-top-left-radius: 24px;
    border-top-right-radius: 24px;
    border-bottom-left-radius: 0;
    border-bottom-right-radius: 0;
    padding-bottom: 5px;
    width: 95%;
  }
  .frame-35811 {
    padding: 15px;
  }
  .note-title {
    font-size: 12px;
  }
  .input-sections1 {
    width: 100%;
  }
  .create-a-manual-order {
    font-size: 14px;
  }
  .frame-35654 {
    max-height: 89vh;
    overflow-y: auto;
    overflow-x: hidden;
    padding: 10px 15px;
    scrollbar-width: none;
    -ms-overflow-style: none;
  }
  .frame-35656 {
    gap: 10px;
  }
  .frame-35815,
  .frame-35816,
  .frame-35817,
  .frame-35814 {
    flex-direction: column;
    gap: 10px;
  }
  .frame-35744 {
    gap: 10px;
  }
  .input-field,
  input[type="tel" i] {
    margin: 0;
    padding: 5px 20px !important;
    font-size: 16px;
  }
  .frame-35559 input {
    margin: 0;
    padding: 5px 20px !important;
    height: auto;
    font-size: 16px;
  }
  .frame-35739,
  .frame-35740,
  .additional-address,
  .frame-35807 {
    width: 100% !important;
    font-size: 16px;
  }
  textarea {
    height: 60px;
    margin: 0;
  }
  .frame-35744 div div {
    margin-left: 20px !important;
    text-align: left;
    width: fit-content !important;
  }
  .input-group,
  .frame-35753 {
    width: 100% !important;
  }
  .frame-35744 div select {
    padding: 5px 20px !important;
  }
  .country-results div,
  .remember-me,
  .in-8-hrs,
  .all-currency-wrapper {
    font-size: 14px;
  }
  .rectangle-10 {
    width: 14px;
    height: 14px;
  }
  .upload-proforma-invoice-optional,
  .select-your-report {
    font-size: 14px;
  }
  .frame-35743 {
    padding: 5px 15px !important;
  }
  input[type="file" i] {
    padding: 8px 0 !important;
  }
  .order-total {
    margin-top: 20px;
    flex-direction: column;
    gap: 15px;
    align-items: flex-start;
  }
  .all-currency-wrapper {
    width: 100%;
    justify-content: space-between;
  }
  .frame-35630 {
    gap: 20px;
  }
  .currency-dropdown {
    width: 30% !important;
    height: 27.22px;
    padding: 0 10px;
  }
  .rectangle-10:checked:after {
    content: none;
    color: white !important;
    font-size: 15px;
    position: absolute;
    left: 50%;
    top: 50%;
    transform: translate(-50%, -50%);
  }
  .country-dropdown {
    padding: 0 10px !important;
  }
  .frame-35806 {
    width: 100%;
  }
  input[type="date"] {
    font-size: 14px;
  }
}
@media only screen and (min-width: 768px) and (max-width: 1156px) and (max-height: 800px) {
  .frame-35654 {
    max-height: 77vh;
    overflow-y: auto;
    overflow-x: hidden;
    scrollbar-width: none;
  }
  .notes {
    padding: 12px;
  }
  .note-title {
    font-size: 16px;
  }
}
@media only screen and (min-width: 1156px) and (max-height: 800px) {
  .frame-35654 {
    max-height: 77vh;
    overflow-y: auto;
    overflow-x: hidden;
    scrollbar-width: none;
  }
}
</style>

<style>
.multiselect {
  width: 100%;
  height: 100px;
}
.multiselect {
  transition: all 0.3s ease;
}
.multiselect__content-wrapper {
  position: absolute;
  z-index: 999;
  width: 100%;
}
.multiselect__input {
  margin: 0 !important;
  border: none;
  outline: none;
  box-shadow: none;
}
.multiselect__tags {
  height: 100px;
  border: none;
  overflow-y: scroll;
}
</style>
