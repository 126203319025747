// store.js
import { createStore } from 'vuex';
import VueCookies from 'vue-cookies';
import createPersistedState from 'vuex-persistedstate';

export default createStore({
  state: {
    isLoggedIn: VueCookies.get('token') !== undefined,
    sessionExpired: false,
    cartCount: 0,
    referralCode: '',
    credits: 0,
    isReferralCodePresent: false,
    userDetails: null,
  },
  mutations: {
    setLoggedIn(state, value) {
      state.isLoggedIn = value;
    },
    setSessionExpired(state, value) {
        state.sessionExpired = value;
    },
    setCartCount(state, count) {
      state.cartCount = count;
    },
    setReferralCode(state, referralCode) {
      state.referralCode = referralCode;
    },
    setCredits(state, credits) {
      state.credits = credits;
    },
    setUserDetails(state, userDetails) {
      state.userDetails = userDetails;
    },
    setIsReferralCodePresent(state, value) {
      state.isReferralCodePresent = value;
    },
    clearUserState(state) {
      state.referralCode = '';
      state.credits = 0;
      state.cartCount = 0;
      state.isLoggedIn = false;
      state.userDetails = null;
    },
  },
  actions: {
    processLogout({ commit }) {
      commit('clearUserState');
      commit('setSessionExpired', true);
    //   window.location.replace('/');
    },
    clearData({ commit }) {
      commit('clearUserState');
    },
    updateCartCount({ commit }, count) {
      commit('setCartCount', count);
    },
    setReferralCode({ commit }, referralCode) {
      commit('setReferralCode', referralCode);
    },
    setCredits({ commit }, credits) {
      commit('setCredits', credits);
    },
    setIsReferralCodePresent({ commit }, value) {
      commit('setIsReferralCodePresent', value);
    },
    setUserDetails({ commit }, userDetails) {
      commit('setUserDetails', userDetails);
    },
  },
  getters: {
    isLoggedIn: state => state.isLoggedIn,
    sessionExpired: state => state.sessionExpired,
    cartCount: state => state.cartCount,
    referralCode: state => state.referralCode,
    credits: state => state.credits,
    isReferralCodePresent: state => state.isReferralCodePresent,
    userDetails: state => state.userDetails,
  },
  plugins: [createPersistedState()],
});
