<template>
  <div class="blog-editor container">
    <div class="routes" v-if="this.blogId">
      <span @click="goBack">Existing Blog List</span
      ><i class="fa-solid fa-angle-right"></i><span>{{ blog?.title }}</span>
    </div>
    <h1>{{ pageTitle }}</h1>
    <form @submit.prevent="submitBlog" class="blog-editor-form">
      <div class="form-group" style="gap: 15px">
        <label for="bannerImage"
          >Thumbnail Image <span style="color: red">*</span></label
        >
        <img
          v-if="this.blogId && blog.bannerImage"
          :src="getBannerImageSrc(blog.bannerImage)"
          class="blog-image"
          alt="Blog Banner Preview"
        />
        <input
          type="file"
          @change="handleBannerUpload"
          class="form-control-file"
          id="bannerImage"
          style="box-shadow: none"
          :required="!this.blogId"
          ref="bannerInput"
        />
      </div>
      <div class="form-group">
        <label for="title">Title <span style="color: red">*</span></label>
        <input
          v-model="blog.title"
          type="text"
          class="form-control"
          id="title"
          placeholder="Enter blog title"
          @input="generateSlug"
          required="true"
        />
      </div>

      <div class="form-group">
        <label for="slug">Slug <span style="color: red">*</span></label>
        <input
          v-model="blog.slug"
          type="text"
          class="form-control"
          id="slug"
          placeholder="URL slug"
          required="true"
        />
      </div>

      <div class="form-group">
        <label for="content">Body <span style="color: red">*</span></label>
        <editor
          v-model="blog.content"
          id="content"
          api-key="6jgzar1a6ieaa8giigvuqbf87kmdkxlyioawss0viuv2oqv1"
          :init="tinymceOptions"
        ></editor>
      </div>

      <div class="form-group">
        <label for="excerpt">Summary <span style="color: red">*</span></label>
        <textarea
          v-model="blog.excerpt"
          class="form-control"
          id="excerpt"
          maxlength="160"
          placeholder="Short summary of the blog"
          required="true"
        ></textarea>
        <small class="char-count"
          >{{ blog.excerpt.length }} / {{ excerptMaxLength }} characters</small
        >
      </div>

      <div class="form-group">
        <label for="tags">Tags <span style="color: red">*</span></label>
        <input
          v-model="blog.tags"
          type="text"
          class="form-control"
          id="tags"
          required="true"
          placeholder="Enter tags (comma-separated)"
        />
      </div>

      <div class="fields-wrapper">
        <div class="form-group" style="width: 50%">
          <label for="category"
            >Category <span style="color: red">*</span></label
          >
          <select
            v-model="blog.category"
            required="true"
            class="form-control"
            id="category"
            style="align-self: stretch"
          >
            <option value="">Select Category</option>
            <option
              v-for="category in categories"
              :key="category"
              :value="category"
            >
              {{ category }}
            </option>
          </select>
        </div>
        <div class="form-group" style="width: 50%">
          <label for="publicationDate"
            >Publication Date <span style="color: red">*</span></label
          >
          <input
            v-model="blog.publicationDate"
            type="date"
            class="form-control"
            id="publicationDate"
            required="true"
          />
        </div>
      </div>

      <div class="fields-wrapper">
        <div class="form-group" style="width: 50%">
          <label for="author">Author <span style="color: red">*</span></label>
          <input
            v-model="blog.author"
            type="text"
            class="form-control"
            id="author"
            required="true"
            placeholder="Author name"
          />
        </div>
        <div class="form-group" style="width: 50%">
          <label for="readTime"
            >Read Time <span style="color: red">*</span></label
          >
          <input
            v-model="blog.readTime"
            type="number"
            class="form-control"
            id="readTime"
            placeholder="Estimated read time (in minutes)"
            required="true"
          />
        </div>
      </div>

      <div class="form-group">
        <label for="seoTitle"
          >Meta Title <span style="color: red">*</span></label
        >
        <input
          v-model="blog.seoTitle"
          type="text"
          class="form-control"
          id="seoTitle"
          maxlength="100"
          placeholder="Meta title (Character length should be between 65 to 75)"
          required="true"
        />
        <small class="char-count"
          >{{ blog?.seoTitle?.length }} / 100 characters</small
        >
      </div>

      <div class="form-group">
        <label for="metaDescription"
          >Meta Description <span style="color: red">*</span></label
        >
        <textarea
          v-model="blog.metaDescription"
          class="form-control"
          id="metaDescription"
          maxlength="160"
          placeholder="Short description for search engines"
          required="true"
        ></textarea>
        <small class="char-count"
          >{{ blog.metaDescription.length }} /
          {{ excerptMaxLength }} characters</small
        >
      </div>

      <button
        type="submit"
        :disabled="submitButtonVisible"
        :style="{ opacity: submitButtonVisible ? '0.6' : '1' }"
        class="btn btn-primary"
      >
        Submit
      </button>
    </form>
  </div>
  <Toastify ref="toastContainer" />
</template>

<script>
import Editor from "@tinymce/tinymce-vue";
import { AddBlog, BlogBannerUpload, GetBlog, UpdateBlog } from "@/api/APIs";
import config from "@/config.js";
import Toastify from "@/components/Toastify.vue";

export default {
  name: "BlogsEditor",
  components: {
    editor: Editor,
    Toastify,
  },
  props: {
    isEditMode: {
      type: Boolean,
      required: true,
    },
  },
  data() {
    return {
      blogBanner: null,
      blog: {
        title: "",
        slug: "",
        content: "",
        bannerImage: null,
        excerpt: "",
        category: "",
        tags: "",
        author: "",
        publicationDate: "",
        seoTitle: "",
        metaDescription: "",
        readTime: null,
      },
      categories: config.blogCategories,
      tinymceOptions: {
        height: "80vh",
        width: "100%",
        menubar: true,
        plugins: "lists link image table code help wordcount",
        toolbar:
          "undo redo | formatselect | bold italic underline | alignleft aligncenter alignright | bullist numlist outdent indent | h1 h2 h3 h4 h5",
        style_formats: [
          { title: "Heading 1", format: "h1" },
          { title: "Heading 2", format: "h2" },
          { title: "Heading 3", format: "h3" },
          { title: "Heading 4", format: "h4" },
          { title: "Heading 5", format: "h5" },
          { title: "Paragraph", format: "p" },
        ],
      },
      submitButtonVisible: false,
      excerptMaxLength: 160,
      pageTitle: "Create a New Blog Post",
      editBlogData: {},
    };
  },
  computed: {
    isExistingBlogEditor() {
      const urlParams = new URLSearchParams(window.location.search);
      return !!urlParams.get("blogId");
    },
  },
  created() {
    const urlParams = new URLSearchParams(window.location.search);
    this.blogId = urlParams.get("blogId");

    if (this.blogId) {
      this.pageTitle = "Edit a Blog Post";
      this.fetchBlogDetails();
    }
  },
  watch: {
    isEditMode: {
      immediate: true,
      handler(newValue) {
        if (!newValue) {
          this.resetBlogForm();
        }
      },
    },
  },
  methods: {
    getBannerImageSrc(bannerImage) {
      return (
        this.$root.getCDNlink(bannerImage) || "../../assets/blogBanner1.webp"
      );
    },
    goBack() {
      this.$router.push("/existing-blogs");
    },
    async fetchBlogDetails() {
      const payload = {
        blogId: this.blogId,
      };

      try {
        const response = await GetBlog(payload);
        if (response.message === "SUCCESS") {
          this.editBlogData = response.blog;
          this.populateFormFields(this.editBlogData);
        } else {
          return this.$refs.toastContainer.addToast(response.message, {
            type: "error",
          });
        }
      } catch (error) {
        if (error.response && error.response.status !== 403) {
          return this.$refs.toastContainer.addToast(
            "Something went wrong. Please try again later.",
            { type: "error" }
          );
        }
      }
    },
    populateFormFields(data) {
      this.blog = {
        ...data,
        excerpt: data.summary,
        metaDescription: data.meta,
        seoTitle: data.seotitle,
      };
    },
    async handleBannerUpload(event) {
      const file = event.target.files[0];

      if (!file) {
        return;
      }

      const allowedTypes = [
        "application/jpg",
        "application/jpeg",
        "application/webp",
        "application/png",
      ];

      if (
        !allowedTypes.includes(file.type) &&
        !file.type.startsWith("image/")
      ) {
        event.target.value = "";
        return this.$refs.toastContainer.addToast(
          "Invalid file type. Please upload a valid file.",
          { type: "error" }
        );
      }

      const formData = new FormData();
      formData.append("file", file);
      try {
        const response = await BlogBannerUpload(formData);
        if (
          response &&
          response.message === "SUCCESS" &&
          response.fileResults.length > 0
        ) {
          const fileResult = response.fileResults[0];
          this.blog.bannerImage = fileResult.fileKey;
        }
      } catch (error) {
        this.loginError = "Error occurred during upload.";
        console.error(error);
      }
    },
    generateSlug() {
      this.blog.slug = this.blog.title
        .toLowerCase()
        .replace(/[^a-z0-9]+/g, "-")
        .replace(/(^-|-$)/g, "");
    },
    async submitBlog() {
      this.submitButtonVisible = true;
      const payload = {
        title: this.blog.title,
        slug: this.blog.slug,
        content: this.blog.content,
        bannerImage: this.blog.bannerImage,
        summary: this.blog.excerpt,
        category: this.blog.category,
        tags: this.blog.tags,
        author: this.blog.author,
        publicationDate: this.blog.publicationDate,
        seotitle: this.blog.seoTitle,
        meta: this.blog.metaDescription,
        readTime: this.blog.readTime,
      };

      try {
        let response;
        if (this.blogId) {
          const editPayload = {
            blogId: this.blogId,
            updateObject: payload,
          };
          response = await UpdateBlog(editPayload);
          if (response.message === "SUCCESS") {
            this.$refs.toastContainer.addToast(
              "Blog details updated successfully",
              {
                type: "success",
              }
            );
            return;
          } else {
            return this.$refs.toastContainer.addToast(response.message, {
              type: "error",
            });
          }
        } else {
          response = await AddBlog(payload);
          if (response.message === "SUCCESS") {
            this.resetBlogForm();
            this.$refs.toastContainer.addToast("Blog submitted successfully!", {
              type: "success",
            });
            return;
          } else {
            return this.$refs.toastContainer.addToast(response.message, {
              type: "error",
            });
          }
        }
      } catch (error) {
        if (error.response && error.response.status !== 403) {
          return this.$refs.toastContainer.addToast(
            "Something went wrong. Please try again later.",
            { type: "error" }
          );
        }
      } finally {
        this.submitButtonVisible = false;
      }
    },
    resetBlogForm() {
      this.blog = {
        title: "",
        slug: "",
        content: "",
        bannerImage: null,
        excerpt: "",
        category: "",
        tags: "",
        author: "",
        publicationDate: "",
        seoTitle: "",
        metaDescription: "",
        readTime: null,
      };

      if (this.$refs.bannerInput?.value) {
        this.$refs.bannerInput.value = "";
      }
    },
  },
};
</script>

<style scoped>
.blog-editor {
  /* margin: 0 auto; */
  padding: 4rem 0 2rem 0;
  margin-left: 18vw;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: flex-start;
  gap: 20px;
}
.form-group {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: center;
  align-self: stretch;
  gap: 10px;
  width: 70vw;
}
.form-group label {
  font-family: "Montserrat-Regular", sans-serif;
  font-size: 16px;
  font-style: normal;
  font-weight: 600;
  line-height: 150%;
  letter-spacing: 0.18px;
  text-align: left;
}
.form-group input {
  align-self: stretch;
  border: none;
  outline: none;
  height: 40px;
  border-radius: 8px;
  box-shadow: 0 1px 2px #aaa;
  padding: 0px 20px;
  margin: 0;
}
.form-group textarea {
  align-self: stretch;
  border: none;
  outline: none;
  height: 10vh;
  margin: 0;
}
.form-group select {
  height: 40px;
  border: none;
  outline: none;
  border-radius: 8px;
  box-shadow: 0 1px 2px #aaa;
  padding: 0px 20px;
}
.blog-editor-form {
  gap: 10px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: flex-start;
}
.btn {
  margin-top: 20px;
  background: #11365a;
  border: none;
  outline: none;
  width: 18vw;
}
.custom-upload-button {
  display: inline-block;
  background-color: #11365a;
  color: white;
  padding: 5px 20px;
  border-radius: 8px;
  cursor: pointer;
  font-family: "Montserrat-Regular", sans-serif;
}
.custom-upload-button:hover {
  background-color: #0d2b48;
}
.file-name {
  margin-left: 15px;
  font-family: "Montserrat-Regular", sans-serif;
  color: #555;
}
.char-count {
  font-size: 12px;
  color: #555;
  margin-top: 5px;
  align-self: stretch;
  text-align: right;
}
.fields-wrapper {
  display: flex;
  flex-direction: row;
  align-self: stretch;
  gap: 20px;
}
.back-button {
  background: none;
  border: none;
  color: #11365a;
  cursor: pointer;
  font-size: 16px;
  margin-right: 10px;
}
.routes {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-start;
  gap: 8px;
  font-family: "Montserrat-Regular", sans-serif;
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: 150%;
  letter-spacing: 0.18px;
  color: gray;
  width: 90%;
}
.routes span {
  cursor: pointer;
}
.blog-image {
  width: 40vw;
  border-radius: 13px;
}

@media only screen and (max-width: 767px) {
  .blog-editor {
    padding: 0rem 0 2rem 0;
    margin-left: 0;
  }
  h1 {
    font-size: 20px;
  }
  .form-group {
    width: 90vw;
  }
}
@media only screen and (min-width: 768px) and (max-width: 1022px) {
  .blog-editor {
    padding: 0rem 0 2rem 0;
    margin-left: 0;
  }
}
</style>
